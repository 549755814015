export const Arabic = {
  dashboard: "الصفحه الرئيسيه",
  admins: "المدراء",
  admin: "المشرف",
  rules: "صلاحيات",
  users: "المستخدمين",
  categories: "التصنيفات",
  category: "التصنيف",
  notification: "الاشعارات",
  promo_codes: "اكواد الخصم",
  promo_code: "اكواد الخصم",
  time_slot: "اوقات العمل",
  banners: "لافتات",
  ad_screen: "شاشه الاعلانات",
  control: "تحكم",
  setting: "اعدادات",
  delivery: "توصيل",
  social_media: "وسائل التواصل",
  social: "وسائل التواصل",
  education: "التعليم",
  sportive: "رياضي",
  other: "آخر",
  pages: "الصفحات",
  daily: "يومي",
  add_admin: "إضافة المشرف",
  name: "الإسم",
  first_name: "الإسم الاول",
  last_name: "الإسم الآخير",
  email: "بريد إلكتروني",
  address: "العنوان",
  phone: "رقم التليفون",
  password: "الرقم السري",
  country_code: "كود الدوله",
  permissions: "الأذونات",
  status: "حالة",
  rule: "إذن",
  full_permissions: "أذونات كاملة",
  some_permissions: "بعض الأذونات",
  no_permissions: "لا أذونات",
  read_only: "قراءه فقط",
  edit: "تعديل",
  delete: "مسح",
  search_by: "البحث عن طريق",
  profile: "الحساب الشخصي",
  logout: "تسجيل الخروج",
  cancel: "إلغاء",
  submit: "إضافه",
  add: "إضافه",
  select: "اختار",
  deleted: "تم الحذف",
  next: "التالي",
  previous: "السابق",
  go_to_page: "الذهاب الي صفحه",
  page: "صفحه",
  of: "من",
  active: "نشط",
  not_active: "غير نشط",
  image: "الصوره",
  deactive: "تعطيل",
  english: "اللغه الانجليزيه",
  arabic: "اللغه العربيه",
  category_image: "صوره التصنيف",
  close: " اغلاق",
  price: "السعر",
  in_stock: "متوفره",
  english_title: "العنوان باللغة الانجليزية",
  english_name: "الإسم باللغة الانجليزية",
  arabic_title: "العنوان باللغة العربية",
  arabic_name: "الإسم باللغة العربية",
  english_description: "الوصف باللغة الإنجليزية",
  arabic_description: "الوصف باللغة العربية",
  description: "الوصف",
  images: "الصور",
  create: "إنشاء",
  by_english: " باللغة الإنجليزية",
  by_arabic: " باللغة العربية",
  add_new_value: "إضافة قيمة جديدة",
  chosse: "اختيار",
  has_amount: "لديه كميه",
  amount: "الكميه",
  available_amount: "الكميه المتاحه",
  payment_method: "طريقه الدفع",
  total_price: "السعر الكلي",
  sub_price: "السعر الجزئي",
  total: "الكلي",
  customer_name: "إسم العميل",
  exist_user: "عميل موجود",
  new_user: "عميل جديد",
  search: "بحث",
  order_day: "يوم التوصيل",
  from: "من",
  to: "إلي",
  coupon: "كود الخصم",
  apply: "تطبيق",
  enter: "أدخل",
  add_new_product: "إضافه منتج جديد",
  date_range: "المده",
  export: "تصدير",
  visa: "فيزا",
  cash: "كاش",
  type: "النوع",
  end_date: "تاريخ الإنتهاء",
  start_date: "تاريخ البدأ",
  or: "أو",
  title: "العنوان",
  message: "الرساله",
  send: "إرسال",
  delete_message:
    "يرجى ملاحظة أنه لا يمكن التراجع عن هذا الإجراء وسيتم حذف التفاصيل.",
  welcome_back: "مرحبا بعودتك",
  sign_text: "قم بتسجيل الدخول عن طريق إدخال المعلومات أدناه",
  text: "أعمال جيدة جدا في انتظاركم",
  sign_in: "تسجيل الدخول",
  open: "فتح",
  rate: "معدل",
  country_name: "اسم الدولة",
  country: "الدولة",
  banner: "لافته",
  link: "رابط",
  ad: "إعلان",
  ad_name: "إسم الإعلان",
  add_new_screen: "إضافه شاشه أخري",
  website_title: "عنوان موقع الويب",
  website_logo: "شعار موقع الويب",
  mobile_logo: "شعار الجوال",
  dashboard_logo: "شعار لوحة المعلومات",
  color: "اللون",
  label_color: "لون النصوص",
  possible_delivery: "امكانيه التوصيل",
  cash_in_delivery_fees: "رسوم تسليم النقد",
  shipping_fee: "رسوم الشحن",
  price_per_kg: "السعر للكيلو جرام",
  type_of_area: "نوع المنطقة",
  all_area: "لكل المناطق",
  per_area: "للمنطقة",
  delivery_fees: "رسوم التوصيل",
  facebook: "فيسبوك",
  snapchat: "سناب شات",
  instagram: "إنستغرام",
  whatsapp: "واتس اب",
  twitter: "تويتر",
  linked_in: "لينكد إن",
  youtube: 'يوتيوب',
  telegram: "تيليجرام",
  tiktok: "تيك توك",
  app_store: "متجل أبل",
  play_store: "متجر جوجل",
  call_us: "اتصل بنا",
  iban: "رقم الآيبان",
  about: "حول",
  faqs: "الأسئلة الشائعة",
  privacy: "الخصوصية",
  address_name: "العنوان",
  block: "كتلة",
  street: "الشارع",
  area: "المنطقة",
  first: "أولا",
  avenue: "الجادة",
  building_number: "رقم المبنى",
  floor_number: "رقم الطابق",
  office_number: "رقم المكتب",
  house_number: "رقم المنزل",
  apt_number: "رقم الشقة",
  other_instructions: "تعليمات أخرى",
  delivery_day: "يوم التوصيل",
  time: "الوقت",
  confirm_added: "تأكيد الإضافة",
  added: "إضافه",
  confirm_des: "يمكنك إضافة منتج آخر عن طريق تغيير بعض المعلومات",
  add_more_products: "أضف المزيد من نفس المنتج",
  reference_id: "الرقم المرجعي",
  invoice_id: "رقم الفاتورة",
  post_date: "التاريخ",
  createdAt: "أنشئت في",
  order_id: "رقم الطلب",
  static_pages: "الصفحات الثابته",
  help_message: "يجب أن تتكون كلمة المرور الخاصة بك من 8 أحرف على الأقل",
  field_required: "هذا الحقل مطلوب",
  governorate: "محافظة",
  all_governorate: "كل المحافظات",
  ads: "إعلانات",
  help_center: "مركز المساعدة",
  num_of_users: "عدد المستخدمين",
  num_of_services: "عدد الخدمات",
  num_of_death: "عدد الوفيات",
  num_of_dawawen: "عدد الدواوين",
  num_of_news: "عدد الأخبار",
  num_of_ads: "عدد الإعلانات",
  num_of_admins: "عدد المديرين",
  num_of_offers: "عدد العروض",
  num_of_events: "عدد الأحداث",
  search_here: "ابحث هنا",
  recent_serviceses_added: "أحدث الخدمات المضافة",
  name_of_service: "اسم الخدمة",
  location: "الموقع",
  verification: "التحقق",
  services: "الخدمات",
  events: "الأحداث",
  deaths: "الوفيات",
  news: "الأخبار",
  dawawen: "الدواوين",
  updated_today: "تم التحديث اليوم",
  updated: "تم التحديث",
  update: "التحديث",
  successfully: "بنجاح",
  hospital: "مستشفى",
  co_operates: "يتعاون",
  clubs: "الأندية",
  citizen_services: "خدمات المواطنين",
  post_offices: "مكاتب البريد",
  banks: "البنوك",
  police_station: "مركز الشرطة",
  school: "المدرسة",
  details: "تفاصيل",
  add_hospital: "إضافة مستشفى",
  write_services_name: "اكتب اسم الخدمة",
  service_image: "صورة الخدمة",
  url: "الرابط",
  manual: "يدوي",
  add_reacts_for_user: "إضافة تفاعلات للمستخدم",
  comments: "التعليقات",
  reacts: "يتفاعل",
  share: "شارك",
  likes: "اعجابات",
  view_details: "عرض التفاصيل",
  view: "عرض",
  offers: "عروض",
  date: "التاريخ",
  account: "الحساب",
  escalations: "التصعيد",
  payments: "المدفوعات",
  precentage_of_users: "نسبة المستخدمين",
  added_photo: "الصور المضافة",
  admin_ads: "إعلانات المشرف",
  users_ads: "إعلانات المستخدمين",
  plan: "خطة",
  plans: "خطط",
  duration: "المدة",
  hour: "ساعه",
  payment_activation: "تفعيل الدفع",
  currency: "العمله",
  save: "حفظ",
  suggest: "يقترح",
  compian: "Compian",
  reply: "الرد",
  kuwait: " العاصمة",
  hawally: " حولي",
  mubarak_kabeer: " مبارك الكبير",
  ahmadi: " الأحمدي",
  farwaniya: " الفروانية",
  jahraa: " الجهراء",
  new_image: "صوره جديده",
  reacts_for_user: "تفاعلات مع المستخدم",
  user: "مستخدم",
  male: "ذكر",
  female: "أنثي",
  price_for: "السعر لـ",
  for: "لـ",
  days: 'أيام',
  day: 'يوم',
  kwd: 'دينار',
  preview: 'معاينة',
  change_password: 'تغيير كلمه المرور',
  change: 'تغيير',
  old_password: 'كلمه المرور القديمه',
  new_password: 'كلمه المرور الجديده',
  suggestions: 'اقتراحات', 
  complain: 'شكوى'
};
