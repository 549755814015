import { Card, Col, Form, Row } from "react-bootstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Translate } from "../../Enums/Tranlate";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import GovernorateService from "../../../services/GovernorateService";
import AreasService from "../../../services/AreasServices";
import profile from '../../../images/icons/profile.svg'
import PlanService from "../../../services/PlanServices";
import BaseService from "../../../services/BaseService";
import uploadImg from '../../../images/upload-img.png';
import Loader from "../../common/Loader";
import AdsServices from "../../../services/AdsServices";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AddAds = () =>{
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        category: '',
        area: '',
        phone: '',
        plan: '',
        duration: '',
        governorate: '',
        images: [],
        price: ''
    })
    const [loading, setLoading] = useState(false)
    const [governorateOptions, setGovernorateOptions] = useState([])
    const [priceOptions, setPriceOptions] = useState([])
    const [areaOptions, setAreaOptions] = useState([])
    const [plansOptions, setPlansOptions] = useState([])
    const lang = useSelector((state) => state.auth?.lang);
    const admin = useSelector((state) => state.auth.auth.admin);

    let categoryOptions = [
        {label: Translate[lang].social, value: 'social'},
        {label: Translate[lang].education, value: 'education'},
        {label: Translate[lang].sportive, value: 'sportive'},
        {label: Translate[lang].other, value: 'other'},
    ]
    const governorateService = new GovernorateService()
    const areasService = new AreasService()
    const planService = new PlanService()
    const adsServices = new AdsServices()
    const navigate = new useNavigate()

    useEffect(()=>{
        governorateService?.getList().then(res=>{
            if(res.status === 200){
                let data = res.data?.data?.map(gov=>{
                    return {
                        label: lang === 'en' ? gov?.name_en : gov?.name_ar,
                        value: gov.id
                    }
                })
                setGovernorateOptions(data)
            }
        })
        planService?.getList().then(res=>{
            if(res.status === 200){
                let data = res.data?.data?.map(plan=>{
                    return {
                        ...plan,
                        label: `${plan.duration} ${plan.duration <= 7 ? Translate[lang].days : Translate[lang].day}`,
                        value: plan.id
                    }
                })
                setPlansOptions(data)
            }
        })
    },[])

    useEffect(()=>{
        if(formData.plan){
            let data = formData.plan.ads_prices?.map(res=>{
                return{
                    ...res,
                    label: `${res.price} ${Translate[lang].kwd} ${Translate[lang].for} ${Translate[lang][res.type]}`,
                    value: res.id
                }
            })
            setPriceOptions(data)
        }
    }, [formData.plan])

    useEffect(()=>{
        if(!!formData?.governorate){
            areasService?.getList(formData?.governorate?.value).then(res=>{
                if(res.status === 200){
                    let data = res.data?.data?.map(gov=>{
                        return {
                            label: lang === 'en' ? gov?.name_en : gov?.name_ar,
                            value: gov.id
                        }
                    })
                    setAreaOptions(data)
                }
            })
        }
    },[formData.governorate])

    const inputHandler = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const fileHandler = (e) => {
        setLoading(true)
        let files = e.target.files
        const filesData = Object.values(files)
        let uploadImages = (file) => new BaseService().postUpload(file)
        Promise.all([...filesData?.map(file=> uploadImages(file))]).then(res=>{
            setFormData({ ...formData, images: [...formData.images, ...res?.map(data=> data.data.url)] });
            setLoading(false);
        })
    }

    const deleteImg = (index) => {
        let update = formData?.images?.filter((_, ind) => ind !== index);
        setFormData({ ...formData, images: update });
    };

    const onSubmit = (e) =>{
        e.preventDefault()
        let data = {
            name: formData?.name,
            category: formData?.category?.value,
            description: formData?.description,
            phoneNumber: formData?.phone,
            start_date: formData?.duration,
            ads_plan_id: formData?.plan?.value,
            ads_price_id: formData.price.id,
            // ads_price_id: (!!formData?.governorate && !!formData?.area) ? formData?.plan?.ads_prices?.filter(res=> res.type==='area')[0].id : (!!formData?.governorate && !formData?.area) ? formData?.plan?.ads_prices?.filter(res=> res.type==='governorate')[0].id : formData?.plan?.ads_prices?.filter(res=> res.type==='all_governorate')[0].id ,
            governorate_id: formData?.governorate?.value,
            area_id: formData?.area?.value,
            images: formData.images
        }
        adsServices?.create(data).then(res=>{
            if(res?.status === 201){
                toast.success(`${Translate[lang].added} ${Translate[lang].ad} ${Translate[lang].successfully}`)
                navigate(`/ads`)
            } 
        })
    }

    return<Card className="add-ads">
        <Card.Body>
            <AvForm onValidSubmit={onSubmit}>
                <Row>
                    <Col md={6}>
                    <Row>
                        <Col sm={12}>
                            <div className='form-group w-100'>
                                <label className="m-0">{Translate[lang].images}</label>
                                <div className="image-placeholder ml-0 mt-2">	
                                    <div className="avatar-edit">
                                        <input type="file" multiple onChange={(e) => fileHandler(e)} id={`imageUpload`} /> 					
                                        <label htmlFor={`imageUpload`}  name=''>
                                            {loading ? <Loader /> : <>
                                             <img 
                                                id={`saveImageFile`} 
                                                src={uploadImg} alt='icon'
                                                style={{ width: '50px', height: '50px',margin: '0 8px' }}
                                            /> 
                                            <p className="m-0">{Translate[lang].add} {Translate[lang].images}</p>
                                            </>}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={12} className='mb-3'>
                            <div style={{display: 'grid', gridTemplateColumns: 'auto auto auto auto auto'}}>
                                {formData?.images?.length > 0 && formData?.images?.map((img)=>{
                                    return <div className="position-relative">
                                        <i 
                                            className="la la-trash text-danger position-absolute cursor-pointer"
                                            style={{
                                                background: '#fff',
                                                fontSize: '18px',
                                                borderRadius: '50%',
                                                padding: '2px 2px',
                                                top: '2px', 
                                                right: '2px'
                                            }}
                                            onClick={()=>{
                                                let filter = formData?.images?.filter(image=> image !== img )
                                                setFormData({...formData, images: [...filter]})
                                            }}
                                        ></i>
                                        <img 
                                            src={img} 
                                            alt='img' 
                                            width={75} 
                                            height={75} 
                                            style={{boxShadow: '1px 2px 7px #dedede'}}
                                        />
                                    </div>
                                })}
                            </div>
                        </Col>
                        <Col md={8}>
                            <AvField
                                label={`${Translate[lang]?.ad_name}`}
                                name="name"
                                type="text"
                                value={formData?.name}
                                errorMessage="Please enter a valid Email"
                                validate={{
                                required: {
                                    value: true,
                                    errorMessage: Translate[lang].field_required,
                                },
                                }}
                                placeholder={`${Translate[lang]?.enter} ${Translate[lang]?.ad_name}`}
                                onChange={(e) => inputHandler(e)}
                            />
                        </Col>
                        <Col md={4}>
                            <label className="text-label">
                            {Translate[lang]?.category}
                            </label>
                            <Select
                                value={formData?.category}
                                name="category"
                                placeholder={Translate[lang]?.select}
                                options={categoryOptions}
                                onChange={(e) => setFormData({ ...formData, category: e })}
                            />
                        </Col>
                        <Col md={12} className='mt-1'>
                            <label className="text-label d-block">
                            {Translate[lang]?.description}
                            </label>
                            <textarea
                                value={formData?.description}
                                name="description"
                                className="w-100"
                                style={{
                                    minHeight: '120px',
                                    padding: '8px',
                                    border: '1px solid hsl(0, 0%, 80%)',
                                    borderRadius: '5px'
                                }}
                                placeholder={Translate[lang]?.description}
                                onChange={(e) => inputHandler(e)}
                            />
                        </Col>
                        <Col md={6} className='mt-2'>
                            <label className="text-label">
                            {Translate[lang]?.governorate}
                            </label>
                            <Select
                                value={formData?.governorate}
                                name="governorate"
                                placeholder={Translate[lang]?.select}
                                options={governorateOptions}
                                onChange={(e) => setFormData({ ...formData, governorate: e })}
                            />
                        </Col>
                        <Col md={6} className='mt-2'>
                            <label className="text-label">
                            {Translate[lang]?.area}
                            </label>
                            <Select
                                value={formData?.area}
                                name="area"
                                placeholder={Translate[lang]?.select}
                                noOptionsMessage={()=> `${Translate[lang].select} ${Translate[lang].governorate} ${Translate[lang].first}`}
                                options={areaOptions}
                                onChange={(e) => setFormData({ ...formData, area: e })}
                            />
                        </Col>
                        <Col md={12} className='mt-2'>
                            <AvField
                                label={`${Translate[lang]?.phone}`}
                                name="phone"
                                type="number"
                                value={formData?.phone}
                                errorMessage="Please enter a valid Email"
                                validate={{
                                required: {
                                    value: true,
                                    errorMessage: Translate[lang].field_required,
                                },
                                }}
                                placeholder={`${Translate[lang]?.enter} ${Translate[lang]?.phone}`}
                                onChange={(e) => inputHandler(e)}
                            />
                        </Col>
                    </Row>
                    </Col>
                    <Col md={6} style={{
                        borderRight: lang === 'ar' ? '1px solid #dedede' : 'none',
                        borderLeft: lang === 'en' ? '1px solid #dedede' : 'none'
                    }}>
                        <Row>
                        <Col md={6} className='mt-2'>
                            <label className="text-label">
                            {Translate[lang]?.plan}
                            </label>
                            <Select
                                value={formData?.plan}
                                name="plan"
                                placeholder={Translate[lang]?.select}
                                options={plansOptions}
                                onChange={(e) => setFormData({ ...formData, plan: e })}
                            />
                        </Col>
                        <Col md={6} className='mt-2'>
                            <label className="text-label">
                            {Translate[lang]?.price}
                            </label>
                            <Select
                                value={formData?.price}
                                name="price"
                                placeholder={Translate[lang]?.select}
                                options={priceOptions}
                                onChange={(e) => setFormData({ ...formData, price: e })}
                            />
                        </Col>
                        <Col md={6} className='mt-3'>
                            <label className="text-label d-block">
                            {Translate[lang]?.start_date}
                            </label>
                            <input
                                value={formData?.duration}
                                name="duration"
                                placeholder={Translate[lang]?.duration}
                                type='date'
                                className="w-100"
                                style={{
                                    padding: '5px',
                                    border: '1px solid hsl(0, 0%, 80%)',
                                    borderRadius: '5px',
                                    textAlign: lang === 'en' ? 'left' : 'right'
                                }}
                                onChange={(e) => inputHandler(e)}
                            />
                        </Col>
                        <Col md={12}>
                            <div className="view">
                                <div className="view-card mt-4 p-3">
                                    <p className="mb-0 text-black">{Translate[lang].preview}</p>
                                    <Card className="mb-0 mt-1" style={{borderRadius: '8px'}}>
                                        <Card.Body>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="user d-flex">
                                                    <img src={profile} width={40} alt='profile' />
                                                    <div className="mx-2">
                                                        <p class='name mb-0 text-black '>{admin.f_name} {admin.l_name}</p>
                                                        <p class='phone mb-0 text-black '>
                                                            <i className="la la-phone"></i>
                                                            {admin.phone}
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* <p class='hour m-0'>{formData.hours} {Translate[lang].hour}</p> */}
                                                <Form.Check
                                                    type="switch"
                                                    id={`custom-switch`}
                                                    checked={true}
                                                />
                                            </div>
                                            <p className="name_of_ad text-black my-3">{formData.name_of_ad || Translate[lang].ad_name}</p>
                                            <p className="badge badge-warning">{formData.category?.label || Translate[lang].category}</p>
                                            <p className="area mb-0">
                                                <label>{Translate[lang].description}:</label>
                                                <span className="text-black mx-2">
                                                {formData.description || Translate[lang].description}
                                                </span>
                                            </p>
                                            <p className="area mb-0">
                                                <label>{Translate[lang].governorate}:</label>
                                                <span className="text-black mx-2">
                                                    {formData.governorate.label || Translate[lang].governorate}
                                                </span>
                                            </p>
                                            <p className="area mb-0">
                                                <label>{Translate[lang].area}:</label>
                                                <span className="text-black mx-2">
                                                    {formData.area.label || Translate[lang].area}
                                                </span>
                                            </p>
                                            <p className="plan mb-0">
                                                <label>{Translate[lang].plan}:</label>
                                                <span className="text-black mx-2">
                                                    {formData.plan?.label || Translate[lang].plan}
                                                </span>
                                            </p>
                                            <p className="duration mb-0">
                                                <label>{Translate[lang].duration}:</label>
                                                <span className="text-black mx-2">
                                                    {formData.duration || 'YYYY-MM-DD'}
                                                </span>
                                            </p>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        </Col>
                        </Row>
                    </Col>
                    <Col md={12} className='mt-4'>
                        <button type="submit" className="btn btn-primary">{Translate[lang].add}</button>
                    </Col>
                </Row>
            </AvForm>
        </Card.Body>
    </Card>
}
export default AddAds;