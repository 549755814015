import { useEffect, useState } from "react";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "../../Enums/Tranlate";
import { Card } from "react-bootstrap";
import Services from "./Services";
import Events from "./Events";
import Deaths from "./Deaths";
import Dewaeen from "./Dewaeen";
import News from "./News";
import { useLocation } from "react-router-dom";
import { setPath } from "../../../store/actions/AuthActions";

const tabs = [
  "hospital",
  "co_operates",
  "clubs",
  "citizen_services",
  "post_offices",
  "banks",
  "police_station",
  "school",
];
const Govenrate = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState("");
  const lang = useSelector((state) => state.auth?.lang);
  const dispatch = useDispatch();
  let { state } = useLocation();
  const path =
    useSelector((state) => state.auth.path) || localStorage.getItem("path");

  useEffect(() => {
    setCategories([
      { label: "services", num: 2300, updated: true },
      { label: "events", num: 2300, updated: false },
      { label: "deaths", num: 2300, updated: true },
      { label: "news", num: 2300, updated: true },
      { label: "dawawen", num: 2300, updated: true },
    ]);
  }, []);

  useEffect(() => {
    setSelectedCategories(path);
  }, [path]);

  return (
    <div className="govenrate">
      <div className="categories">
        {categories?.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                setSelectedCategories(item.label);
                dispatch(setPath(item.label));
              }}
              className={`category ${
                selectedCategories === item.label ? "active" : ""
              }`}
            >
              <h4 className="label d-block">{Translate[lang][item.label]}</h4>
              <span className="num d-block">
                {/* {item.num} {Translate[lang][item.label]} */}
              </span>
            </div>
          );
        })}
      </div>
      {selectedCategories === "services" && (
        <Services
          selectedCategories={selectedCategories}
          tabs={tabs}
          govenrate={state}
        />
      )}
      {selectedCategories === "events" && (
        <Events selectedCategories={selectedCategories} govenrate={state} />
      )}
      {selectedCategories === "deaths" && (
        <Deaths selectedCategories={selectedCategories} govenrate={state} />
      )}
      {selectedCategories === "news" && (
        <News selectedCategories={selectedCategories} govenrate={state} />
      )}
      {selectedCategories === "dawawen" && (
        <Dewaeen selectedCategories={selectedCategories} govenrate={state} />
      )}
    </div>
  );
};
export default Govenrate;
