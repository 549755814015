import BaseService from "./BaseService";
import http from "./HttpService";
import { API_BASE_URL_ENV } from "../jsx/common/common";

const apiEndpoint = API_BASE_URL_ENV() + "/admin/admin";
const apiChangePassword = API_BASE_URL_ENV() + "/admin/changePassword";
const apiTogglle = API_BASE_URL_ENV() + "/admin/block";
const apiDashboard = API_BASE_URL_ENV() + "/admin/dashboard";
const apiVarification = API_BASE_URL_ENV() + "/admin/verifiedUser";
const apiImageProfile = API_BASE_URL_ENV() + "/admin/imageProfile";

export default class AdminService extends BaseService {
  constructor() {
    super(apiEndpoint);
  }
  getDashboard = () => {
    return http.get(apiDashboard);
  };

  create(data) {
    return http.post(`${API_BASE_URL_ENV()}/admin`, data);
  }

  update(id, data) {
    const body = { ...data };
    return http.put(`${API_BASE_URL_ENV()}/admin/${id}`, body);
  }

  updateImageProfile(data) {
    return http.put(apiImageProfile, data);
  }

  remove(id, data) {
    return http.delete(`${API_BASE_URL_ENV()}/admin/${id}`, {data});
  }

  toggleStatus(id, data) {
    return http.put(`${apiTogglle}/${id}`, data);
  }
  
  changeVerification(id, data) {
    return http.put(`${apiVarification}/${id}`, data);
  }

  changePassword(data) {
    return http.put(`${apiChangePassword}`, data);
  }
}
