import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Card, CardBody} from "reactstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import SocialMediaService from "../../../../services/SocialMediaService";
import Loader from "../../../common/Loader";
import { SocialMediaLinks } from "../../../Enums/SocialMedia";
import { Translate } from "../../../Enums/Tranlate";

const SocialMedia = ()=>{
    const [links, setLinks] = useState({})
    const [loading, setLoading] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    // const [selectedSocial, setSelectedSocial] = useState([])
    const Auth = useSelector(state=> state.auth?.auth)
    const lang = useSelector(state=> state.auth?.lang)
    const socialMediaService = new SocialMediaService()
    const isExist = (data)=> Auth?.admin?.admin_roles?.includes(data)

    useEffect(()=>{
        setLoadingData(true)
        socialMediaService?.getList()?.then(res=>{
            if(res.status === 200 && res.data?.data){
                setLinks({...res.data?.data})
            } else{
                let values={}
                SocialMediaLinks?.map(link=> values[link.value]= '')
                setLinks({...values})
            }
            setLoadingData(false)
        })
    },[])

    const inputHandler =(e)=>{
        setLinks({...links,[e.target.name]: e.target.value})
    }

    const onSubmit = (e)=> {
        e.preventDefault()
        let data = {}
        if(!!links.facebook) data['facebook'] = links.facebook
        if(!!links.instagram) data['instagram'] = links.instagram
        if(!!links.whatsapp) data['whatsapp'] = links.whatsapp
        if(!!links.twitter) data['twitter'] = links.twitter
        if(!!links.youtube) data['youtube'] = links.youtube
        if(!!links.telegram) data['telegram'] = links.telegram
        if(!!links.linked_in) data['linked_in'] = links.linked_in

        setLoading(true)
        socialMediaService?.create(data)?.then(res=>{
            if(res.status === 201){
                toast?.success(`${Translate[lang].added} ${Translate[lang].social_media} ${Translate[lang].successfully}`)
            }
            setLoading(false)
        }).catch(error=> {
            toast.error(error)
            setLoading(false)
        })
    }

    if(loadingData){
        return <Card className="py-5" style={{height: '300px'}}>
            <CardBody>
                <Loader />
            </CardBody>
      </Card>
    }

    return(<>
    <Card style={{borderRadius: '8px'}}>
        <CardBody className="position-relative pt-5">
            <form onSubmit={onSubmit}>
                <Row className="mb-3">
                {SocialMediaLinks?.map((link, index)=>{
                    return <Col md={6} className='mb-3' key={index}>
                        <label className="text-label">
                            {Translate[lang][link.value]}
                        </label>
                        <input
                            type="text"
                            name={link.value}
                            className="form-control"
                            placeholder={`${Translate[lang][link.value]} ${Translate[lang].link}`}
                            value={links[link?.value]}
                            onChange={(e)=> inputHandler(e)}
                        />
                    </Col>
                })}
                </Row>
                <div className="d-flex justify-content-end">
                    <div></div>
                    <Button variant="primary" type="submit" disabled={loading}>
                        {Translate[lang].save}
                    </Button>
                </div>
            </form>
        </CardBody>
    </Card>
    </>)
}
export default SocialMedia;