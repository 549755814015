import BaseService from "./BaseService";
import http from './HttpService'

import { API_BASE_URL_ENV } from "../jsx/common/common";

const apiEndpoint = API_BASE_URL_ENV() + "/social/iban";

export default class IbanService extends BaseService {
    constructor() {
        super(apiEndpoint);
    }
    remove() {
        return http.delete(apiEndpoint);
    }
}
