import { useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import profile from "../../../../images/icons/profile.svg";
import AdminService from "../../../../services/AdminService";
import { Translate } from "../../../Enums/Tranlate";

const verificationMenu = [
  'blue', 'gray', 'none'
]
const CardItem = ({ item, index, setShouldUpdate }) => {
  const [verification, setVerification] = useState(null);
  const [status, setStatus] = useState(null);
  const navigate = useNavigate();
  const lang = useSelector((state) => state.auth.lang);
  const adminService = new AdminService()

  useEffect(() => {
    setVerification(item.verification);
    setStatus(!item.isBlocked)
  }, [item]);

  const changeVerification = (state)=>{
    adminService.changeVerification(item.id, {verification: state}).then(res=>{
      if(res.status=== 200){
        toast.success(`${Translate[lang].updated} ${Translate[lang].verification} ${Translate[lang].successfully}`)
        setVerification(state)
      }
    })
  }

  const changeStatus = ()=>{
    adminService.toggleStatus(item.id, {isBlocked: !status}).then(res=>{
      if(res.status=== 200){
        toast.success(`${Translate[lang].updated} ${Translate[lang].status} ${Translate[lang].successfully}`)
        setVerification(!status)
      }
    })
  }

  return (
    <tr key={index}>
      <td>
        <img
          src={profile}
          width="24"
          style={{
            marginRight: lang === "en" ? "12px" : "0px",
            marginLeft: lang === "ar" ? "12px" : "0px",
          }}
          alt="profile"
        />
        {item?.f_name} {item?.l_name}
      </td>
      <td>{lang==='en' ? item.area?.name_en : item.area?.name_ar}</td>
      <td>{Translate[lang][item?.gender]}</td>
      <td>{lang==='en' ? item.governorate?.name_en : item.governorate?.name_ar}</td>
      <td>
      <Dropdown>
          <Dropdown.Toggle
            style={{
              background:
                verification === "blue"
                  ? "#EFF6FF"
                  : verification === "gray"
                  ? "#F5F5F5"
                  : "none",
              padding: "4px 12px",
              borderRadius: " 5px",
              color:
              verification === "blue"
                  ? "#1D4ED8"
                  : verification === "gray"
                  ? "#808080"
                  : "#A7A7A7",
              textTransform: "capitalize",
            }}
            className="light sharp i-false"
          >
            {verification}
          <i
            className="la la-angle-down"
            style={{
              marginRight: lang === "ar" ? "8px" : "0px",
              marginLeft: lang === "en" ? "8px" : "0px",
            }}
          ></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {verificationMenu?.filter(res=> res !== verification)?.map(res=>{
              return <Dropdown.Item 
                style={{textTransform: "capitalize"}}
                onClick={()=> changeVerification(res)}
              >{res}</Dropdown.Item>
            })}
          </Dropdown.Menu>
          </Dropdown>
      </td>
      <td>
        <Form.Check 
          type="switch"
          id="status"
          // onChange={changeStatus}
          checked={status}
        />
      </td>
      <td>
        <Dropdown>
          <Dropdown.Toggle
            // variant="success"
            className="light sharp i-false"
          >
            <i className="la la-ellipsis-v" style={{fontSize: '27px'}}></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {/* <Dropdown.Item>{Translate[lang].edit}</Dropdown.Item>  */}
            <Dropdown.Item>{Translate[lang].delete}</Dropdown.Item>
          </Dropdown.Menu>
          </Dropdown>
        </td>
    </tr>
  );
};
export default CardItem;
