import React, {useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import uploadImg from '../../../images/upload-img.png';
import BaseService from "../../../services/BaseService";
import ScreenService from "../../../services/ScreenService";
import { Translate } from "../../Enums/Tranlate";
import Loader from "../../common/Loader";
import './style.scss'

const AdScreen2 = () =>{
    const [files, setFiles] = useState([{}])
    const screenService = new ScreenService()
    const [isAdd, setIsAdd] = useState(true)
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState([
        {src:'', loading: false}
    ])
    const Auth = useSelector(state=> state.auth?.auth)
    const lang = useSelector(state=> state.auth?.lang)
    const isExist = (data)=> Auth?.admin?.admin_roles?.includes(data)

    useEffect(()=>{
        screenService?.getList()?.then(res=>{
            if(res?.status === 200){
                if(res.data?.data?.length === 0){
                    return
                }
                if(res.data?.data?.length > 0) setIsAdd(false)
                let data = res.data?.data?.map(item=>{
                    return {
                        id: item.id,
                        src: item?.image,
                        loading: false
                    }
                })
                setFormData([...data])
            }
        })
    },[])

    const fileHandler = (e, index) => {
        let filesAll = e.target.files
        const filesData = Object.values(filesAll)
        let updateFormData = formData?.map((item,updateIndex) => {
            if(updateIndex === index){
                return {
                    ...item,
                    loading: true
                }
            } else{
                return item
            }
        })
        setFormData(updateFormData)
        let update = files?.map((file,updateIndex) => {
            if(updateIndex === index){
                return e.target.files[0]
            } else{
                return file
            }
        })
        
        new BaseService().postUpload(filesData[0]).then(res=>{
            if(res && res?.data?.status){
                let updateImages = formData.map((item, ind)=>{
                    if(ind === index){
                        return {
                            ...item,
                            src: res.data.url,
                            loading: false
                        }
                    } else {
                        return {...item}
                    }
                } )
                setFormData([...updateImages])
                setFiles([...update])
            }
        })
    }

    const onSubmit = () => {
        let data = {
            add_screen: formData?.filter(res => !!res.src)?.map((item,index)=>{
                return {
                    image: item?.src, 
                }
            })
        }
        
        if(isAdd){
            if(data.screens?.length === 0){
                toast.error('Add Image First')
                return
             }
            screenService.create(data)?.then(res=>{
                if(res?.status === 201){
                    toast.success('Screen Added Successfully')
                    setIsAdd(false)
                }
            })
        } else {
            screenService.update(data)?.then(res=>{
                if(res?.status === 200){
                    toast.success('Screen Updated Successfully')
                    setIsAdd(false)
                }
            })
        }
        
    }

    const removeScreen = (id, index)=>{
        let update = formData?.filter((_,ind)=> index !== ind)
        setFormData(update)
        screenService.remove(id)?.then(res=>{
            if(res?.status === 200){
                toast.success('Screen Removed Successfully')
            }
        })
    }
    if(loading){
        return <Card className="py-5" style={{height: '300px'}}>
            <Card.Body>
                <Loader />
            </Card.Body>
      </Card>
    }
    return(<Card className="ad-screen">
        <Card.Body>
        {formData?.map((data, index)=>{
            return <Row key={index}>
                <Col md={12} style={{
                    padding: '1rem',
                    boxShadow: '0 0 8px #e0dbdb9e',
                    borderRadius: '16px',
                    marginBottom: '2rem'
                }}>
                <div className='d-flex justify-content-between align-items-center mb-1'>
                    <p style={{fontSize: '21px', marginBottom: '0'}}>{Translate[lang].ad}</p>
                </div>
                <div className="image-placeholder">	
                    <div className="avatar-edit">
                        <input 
                            type="file" 
                            onChange={(e) => {
                                // if(!isExist('ad_screen')){
                                //     toast.error('Not Allowed, Don`t have Permission')
                                //     return
                                // }
                                fileHandler(e,index)
                            }} 
                            id={`imageUpload${index}`} /> 					
                        <label htmlFor={`imageUpload${index}`}  name=''></label>
                    </div>
                    <div className="avatar-preview">
                        <div id={`imagePreview${index}`} className='position-relative'>
                            {!!data?.src && <button 
                                onClick={()=>{
                                    removeScreen(data.id, index)
                                }}
                                style={{
                                    position: 'absolute',
                                    top: '16px',
                                    borderRadius: '50%',
                                    left: '16px',
                                    border: '0',
                                    background: '#FF4847',
                                    color: '#fff',
                                    padding: '4px 7px',
                                    zIndex: '1'
                                 }}
                            >
                                <i className='la la-times' style={{fontSize: '18px'}}></i>
                            </button>}
                            {!!data?.src && <img id={`saveImageFile${index}`} src={data?.src} alt='icon' />}
                            {(!data?.src && !data.loading) && <img id={`saveImageFile${index}`} src={uploadImg} alt='icon'
                                style={{
                                    width: '80px', height: '80px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'
                                }}
                            />}
                            {data.loading && <Loader />}
                        </div>
                    </div>
                </div>
                </Col>
                </Row>
        })}
        {/* isExist('ad_screen') &&  */}
    {<div className="d-flex justify-content-between align-items-center mt-2">
        <Button
            variant="secondary" 
            className="px-5"
            onClick={()=> setFormData([...formData, {src: ''}])}
        >{Translate[lang].add_new_screen}</Button>
        <Button
            variant="primary" 
            className="px-5"
            onClick={()=> onSubmit()}
        >{Translate[lang].submit}</Button>
    </div>}
    </Card.Body>
</Card>)
}
export default AdScreen2;