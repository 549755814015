import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Translate } from "../../../Enums/Tranlate";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { useEffect, useState } from "react";
import Select from "react-select";
import { Button } from "react-bootstrap";
import AdminService from "../../../../services/AdminService";
import { toast } from "react-toastify";
import { changeProfile } from "../../../../store/actions/AuthActions";
import Loader from "../../../common/Loader";
import uploadImg from '../../../../images/upload-img.png';
import BaseService from "../../../../services/BaseService";
const countryCodes = require("country-codes-list");

const Account = ()=>{
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        country_code: "",
        password: "",
        rules: [],
        avatar: '',
        old_password: '',
        new_password: ''
    })
    const lang = useSelector(state=> state.auth?.lang)
    const AuthData = useSelector(state=> state.auth?.auth)
    const Auth = useSelector(state=> state.auth?.auth.admin)
    const [edit, setEdit] = useState(false)
    const [countriesOptions, setCountriesOptions] = useState([]);
    const [changePassword, setChangePassword] = useState(false);
    const adminService = new AdminService();
    const [loading, setLoading] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);
    const dispatch = useDispatch()

    const inputHandler = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        const myCountryCodesObject = countryCodes.customList(
          "countryCode",
          "{countryNameEn}/{countryNameLocal} +{countryCallingCode}"
        );
        const entries = Object.entries(myCountryCodesObject);
        let data = entries?.map((c) => {
            return {
              label: c[1].split("+")[0],
              value: c[1].split("+")[1],
            };
          })
        setCountriesOptions([...data]);
        
        setFormData({
            first_name: Auth.f_name,
            last_name: Auth.l_name,
            email: Auth.email,
            phone: Auth.phone,
            country_code: data?.filter(res=> res.value === Auth.country_code.replace('+',''))?.length > 0 ? data.filter(res=> res.value === Auth.country_code.replace('+',''))[0] : '',
            rules: Auth.admin_roles,
            avatar: Auth.avatar
        })
    }, []);

    const handlerChangePassword = (e) => {
        e.preventDefault();
    
        let data = {
          old_password: formData?.old_password,
          new_password: formData?.new_password,
        };
    
        setLoading(true);
        adminService.changePassword(data).then((response) => {
          if (response?.status === 200) {
            toast.success(
              `${Translate[lang]?.profile} ${Translate[lang]?.updated} ${Translate[lang]?.successfully}`
            );
            setChangePassword(false);
          }
          setLoading(false);
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (!formData.country_code) {
          toast.error("Select Country");
          return;
        }
        let data = {
          f_name: formData?.first_name,
          l_name: formData?.last_name,
          country_code: `+${formData?.country_code?.value}`,
          phone: formData?.phone,
          rules: formData.rules,
        };
        setLoading(true);
        adminService.update(Auth?.id, data).then((response) => {
            if (response?.status === 200) {
              toast.success(
                `${Translate[lang]?.profile} ${Translate[lang]?.updated} ${Translate[lang]?.successfully}`
              );
              setEdit(false);
              localStorage.setItem('userDetails', JSON.stringify({...AuthData,admin: {...AuthData.admin, ...data}}));
              dispatch(changeProfile({...data}))
            }
            setLoading(false);
            
        });
    };

    const fileHandler = (e) => {
        setLoadingImage(true)
        let filesAll = e.target.files
        const filesData = Object.values(filesAll)
        
        new BaseService().postUpload(filesData[0]).then(res=>{
            if(res && res?.data?.status){
                adminService.updateImageProfile({avatar: res.data.url}).then(res2=>{
                    if(res2 && res2?.status === 200){
                        toast.success(`${Translate[lang]?.profile} ${Translate[lang]?.image} ${Translate[lang]?.updated} ${Translate[lang]?.successfully}`)
                        setFormData({...formData, avatar: res.data.url})
                    }
                    localStorage.setItem('userDetails', JSON.stringify({...AuthData,admin: {...AuthData.admin, avatar: res.data.url}}));
                })
            }
            setLoadingImage(false)
        }).catch((e) => {
            toast.error(e?.response?.data?.error)
            setLoadingImage(false)
        })
    }

    return <Card style={{minHeight: '657px', borderRadius: '8px'}}>
        <CardBody className="position-relative">
            <Button 
                variant="light" 
                type="button" 
                style={{
                    position: 'absolute',
                    left: lang === 'ar' ? '30px' : 'auto',
                    right: lang === 'en' ? '30px' : 'auto',
                }}
                className="p-2" 
                onClick={()=> setEdit(true)}
            >
                <i className="la la-edit" style={{fontSize: '22px'}}></i>
            </Button>
            <Row className="px-2 py-4">
                <Col sm={12} md={5}>
                    {!edit && <h3 style={{marginBottom: '24px'}}>{formData?.first_name} {formData?.last_name}</h3>}
                    <p style={{fontSize: '14px', fontWeight: '700', margin: '0', color: '#1A1A1A'}}>{Translate[lang].edit} {Translate[lang].image}</p>
                    <p style={{fontSize: '12px', fontWeight: '400', marginBottom: '16px'}}>
                    Image format .jpg .jpeg .png and minimum size 300 x 300px
                    </p>
                    <div className="avatar position-relative">
                        <div className="avatar-edit position-absolute" style={{
                            right: '-10px',
                            background: '#dedede',
                            borderRadius: '50%',
                            padding: '4px 5px'
                        }} >
                            <input 
                                type="file" 
                                onChange={(e) => fileHandler(e)} 
                                id={`imageUpload`} 
                                className='d-none'
                            /> 					
                            <label htmlFor={`imageUpload`} className='la la-edit m-0' style={{fontSize: '1.3rem'}}></label>
                        </div>
                        <div className="avatar-img" style={{
                            width: 'max-content',
                            border: '1px solid #f5ecec',
                            boxShadow: '0 0 5px #cececec4',
                            borderRadius: '8px'
                        }}>
                            {!formData.avatar && <i className="la la-user" style={{
                                fontSize: '6rem'
                            }} />}
                            {(!!formData.avatar && !loadingImage) && <img src={formData.avatar} style={{borderRadius:'8px'}} alt='avatar' width={96} height={96} />}
                            {loadingImage && <Loader />}
                        </div>
                    </div>

                    {!changePassword ? <AvForm onValidSubmit={onSubmit} className='pt-4'>
                        {edit && <><div className="mb-3">
                                <AvField
                                    label={`${Translate[lang]?.first_name}`}
                                    name="first_name"
                                    type="text"
                                    value={formData?.first_name}
                                    errorMessage="Please enter a valid First Name"
                                    validate={{
                                        required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required,
                                        },
                                    }}
                                    placeholder={`${Translate[lang]?.first_name}`}
                                    onChange={(e) => inputHandler(e)}
                                />
                            </div>
                            <div className="mb-3">
                                <AvField
                                label={`${Translate[lang]?.last_name}`}
                                name="last_name"
                                type="text"
                                value={formData?.last_name}
                                errorMessage="Please enter a valid Last Name"
                                validate={{
                                    required: {
                                    value: true,
                                    errorMessage: Translate[lang].field_required,
                                    },
                                }}
                                placeholder={`${Translate[lang]?.last_name}`}
                                onChange={(e) => inputHandler(e)}
                                />
                            </div>
                        </>}
                        {!edit && <div className="mb-3">
                            <AvField
                                label={Translate[lang]?.email}
                                name ='email'
                                type='text'
                                value={formData?.email}
                                disabled={!edit}
                                placeholder={`${Translate[lang]?.email}`}
                                onChange={(e) => inputHandler(e)}
                            />
                        </div>}
                        <div className="mb-3">
                            <label className="text-label">
                            {Translate[lang]?.country_code}
                            </label>
                            <Select
                            value={formData?.country_code}
                            name="country_code"
                            placeholder={Translate[lang]?.select}
                            options={countriesOptions}
                            onChange={(e) => setFormData({ ...formData, country_code: e })}
                            />
                        </div>
                        <div className="mb-3">
                            <AvField
                            label={`${Translate[lang]?.phone}`}
                            name="phone"
                            type="number"
                            value={formData?.phone}
                            errorMessage="Please enter a valid Phone"
                            validate={{
                                required: {
                                value: true,
                                errorMessage: Translate[lang].field_required,
                                },
                            }}
                            placeholder={`${Translate[lang]?.phone}`}
                            onChange={(e) => inputHandler(e)}
                            />
                        </div>
                        {!edit && <div className="mb-3"> 
                        <span
                            style={{
                            textDecoration: 'underline',
                            color: 'var(--blue)',
                            fontSize: '16px', cursor: 'pointer'
                        
                            }}
                            onClick={()=> setChangePassword(true)}
                        >{Translate[lang].change_password}</span>
                        </div>}
                        {edit && <div className="d-flex justify-content-between mt-3">
                            <Button variant="primary" type="submit">
                                {Translate[lang].update}
                            </Button>
                            <Button variant="secondary" type="button" onClick={()=> setEdit(false)}>
                                {Translate[lang].cancel}
                            </Button>
                        </div>}
                    </AvForm> : <AvForm onValidSubmit={handlerChangePassword}>
                        <div className="mb-2">
                                <AvField
                                label={`${Translate[lang]?.old_password}`}
                                name="old_password"
                                type="text"
                                value={formData?.old_password}
                                errorMessage="Please enter a valid First Name"
                                validate={{
                                    required: {
                                    value: true,
                                    errorMessage: Translate[lang].field_required,
                                    },
                                }}
                                placeholder={`${Translate[lang]?.old_password}`}
                                onChange={(e) => inputHandler(e)}
                                />
                        </div>
                        <div className="mb-2">
                                <AvField
                                label={`${Translate[lang]?.new_password}`}
                                name="new_password"
                                type="text"
                                value={formData?.new_password}
                                errorMessage="Please enter a valid Last Name"
                                validate={{
                                    required: {
                                    value: true,
                                    errorMessage: Translate[lang].field_required,
                                    },
                                }}
                                placeholder={`${Translate[lang]?.new_password}`}
                                onChange={(e) => inputHandler(e)}
                                />
                        </div>
                        <div className="d-flex justify-content-between mt-2">
                            <Button variant="primary" type="submit" disabled={loading}>
                                {Translate[lang]?.change}
                            </Button>
                            <Button
                                variant="secondary"
                                type="button"
                                onClick={() => setChangePassword(false)}
                            >
                                {Translate[lang]?.cancel}
                            </Button>
                        </div>
                    </AvForm>}
                </Col>
            </Row>
        </CardBody>
    </Card>
}
export default Account;