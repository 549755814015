export const English = {
  dashboard: "Dashboard",
  admins: "Admins",
  admin: "Admin",
  rules: "Rules",
  users: "Users",
  categories: "Categories",
  category: "Category",
  notification: "Notification",
  promo_codes: "Promo Codes",
  promo_code: "Promo Codes",
  time_slot: "Time Slot",
  banners: "Banners",
  ad_screen: "Ad Screen",
  control: "Control",
  setting: "Setting",

  pages: "Pages",
  total_users: "Total Users",
  daily: "Daily",
  add_admin: "Add Admin",
  name: "Name",
  first_name: "First Name",
  last_name: "Last Name",
  email: "Email",
  address: "Address",
  password: "Password",
  phone: "Phone",
  country_code: "Country Code",
  permissions: "Permissions",
  status: "STATUS",
  rule: "Rule",
  full_permissions: "Full Permissions",
  some_permissions: "Some Permissions",
  no_permissions: "No Permissions",
  read_only: "Read Only",
  edit: "Edit",
  delete: "Delete",
  search_by: "Search by",
  profile: "Profile",
  logout: "Log Out",
  cancel: "Cancel",
  submit: "Submit",
  add: "Add",
  select: "Select",
  deleted: "Deleted",
  next: "Next",
  previous: "Previous",
  go_to_page: "Go to Page",
  page: "Page",
  of: "of",
  add_categories: "Add Categories",
  active: "Active",
  not_active: "Not Active",
  image: "Image",
  deactive: "Deactive",
  english: "English",
  arabic: "Arabic",
  category_image: "Category Image",
  close: " Close",
  price: "Price",
  in_stock: "In Stock",
  english_title: "English Title",
  english_name: "English Name",
  arabic_title: "Arabic Title",
  arabic_name: "Arabic Name",
  english_description: "English Description",
  arabic_description: "Arabic Description",
  description: "Description",
  images: "Images",
  create: "Create",
  by_english: "by English",
  by_arabic: "by Arabic",
  add_new_value: "Add New Value",
  chosse: "Chosse",
  total: "Total",
  customer_name: "Customer Name",
  exist_user: "Exist User",
  new_user: "New User",
  search: "Search",
  order_day: "Order Day",
  from: "From",
  to: "To",
  coupon: "Coupon",
  apply: "Apply",
  enter: "Enter",
  add_new_product: "Add New Product",
  date_range: "Date Range",
  export: "Export",
  visa: "Visa",
  cash: "Cash",
  type: "Type",
  end_date: "End Date",
  start_date: "Start Date",
  or: "Or",
  title: "Title",
  message: "Message",
  send: "Send",
  delete_message:
    "Kindly note that this action can`t be undone and the details will get deleted.",
  welcome_back: "Welcome Back",
  sign_text: "Sign in by entering information below",
  text: "Very Good Works are Waiting for you",
  sign_in: "Sign In",
  open: "Open",
  rate: "Rate",
  country_name: "Country Name",
  country: "Country",
  banner: "Banner",
  link: "Link",
  ad: "Ad",
  ad_name: "Ad Name",
  add_new_screen: "Add New Screen",
  website_title: "Website Title",
  website_logo: "Website Logo",
  mobile_logo: "Mobile Logo",
  dashboard_logo: "Dashboard Logo",
  color: "Color",
  label_color: "Label Color",
  possible_delivery: "Possible Delivery",
  cash_in_delivery_fees: "Cash in Delivery Fees",
  shipping_fee: "Shipping Fee",
  price_per_kg: "Price per Kg",
  type_of_area: "Type of Area",
  all_area: "All Area",
  per_area: "Per Area",
  delivery_fees: "Delivery Fees",
  facebook: "Facebook",
  snapchat: "Snapchat",
  instagram: "Instagram",
  whatsapp: "Whatsapp",
  twitter: "Twitter",
  linked_in: "Linked In",
  telegram: "Telegram",
  youtube: 'Youtube',
  tiktok: "Tiktok",
  app_store: "App Store",
  play_store: "Play Store",
  call_us: "Call Us",
  iban: "Iban",
  about: "about",
  faqs: "FAQs",
  privacy: "Privacy",
  address_name: "Address Name",
  block: "Block",
  street: "Street",
  area: "Area",
  first: "First",
  avenue: "Avenue",
  building_number: "Building Number",
  floor_number: "Floor Number",
  office_number: "Office Number",
  house_number: "House Number",
  apt_number: "Apt Number",
  other_instructions: "Other Instructions",
  delivery_day: "Delivery Day",
  time: "Time",
  confirm_added: "Confirm Added",
  added: "Added",
  confirm_des: "You can add another product by change some information",
  add_more_products: "Add More Same Product",
  reference_id: "Reference Id",
  invoice_id: "Invoice Id",
  post_date: "Post Date",
  createdAt: "CreatedAt",
  order_id: "Order Id",
  static_pages: "Static Pages",
  help_message: "Your password must have at least 8 characters",
  field_required: "This Field are Required",
  governorate: "Governorate",
  all_governorate: "All Governorate",
  ads: "Ads",
  help_center: "Help Center",
  num_of_users: "Num of Users",
  num_of_services: "Num of Services",
  num_of_death: "Num of Death",
  num_of_dawawen: "Num of Dawawen",
  num_of_news: "Num of News",
  num_of_ads: "Num of Ads",
  num_of_admins: "Num of Admins",
  num_of_offers: "Num of Offers",
  num_of_events: "Num of Events",
  search_here: "Search here",
  recent_serviceses_added: "Recent Serviceses Added",
  name_of_service: "Name of Service",
  location: "Location",
  verification: "Verification",
  services: "Services",
  events: "Events",
  deaths: "Deaths",
  news: "News",
  dawawen: "Dawawen",
  updated_today: "Updated Today",
  updated: "Updated",
  update: "Update",
  successfully: "Successfully",
  hospital: "Hospital",
  co_operates: "Co-Operates",
  clubs: "Clubs",
  citizen_services: "Citizen Services",
  post_offices: "Post Offices",
  banks: "Banks",
  police_station: "Police Station",
  school: "School",
  details: "Details",
  add_hospital: "Add Hospital",
  write_services_name: "Write a Services Name",
  service_image: "Service image",
  url: "URL",
  manual: "Manual",
  add_reacts_for_user: "Add reacts for user",
  comments: "Comments",
  reacts: "Reacts",
  likes: "Likes",
  share: "Share",
  view_details: "View Details",
  view: "View",
  offers: "Offers",
  date: "Date",
  account: "Account",
  social_media: "Social Media",
  social: "Social Media",
  education: "Education",
  sportive: "Sportive",
  other: "Other",
  escalations: "Escalations",
  payments: "Payments",
  precentage_of_users: "Precentage of users",
  added_photo: "Added Photo",
  admin_ads: "Admin Ads",
  users_ads: "Users Ads",
  plan: "Plan",
  plans: "Plans",
  duration: "Duration",
  hour: "Hour",
  payment_activation: "Payment activation",
  payment_method: "Payment Method",
  currency: "Currency",
  save: "Save",
  suggest: "Suggest",
  compian: "Compian",
  reply: "Reply",
  kuwait: "Capital",
  hawally: "Hawally",
  mubarak_kabeer: "Mubarak Al-Kabeer",
  ahmadi: "Ahmadi",
  farwaniya: "Farwaniya",
  jahraa: "Jahra",
  new_image: "New Image",
  reacts_for_user: "Reacts For User",
  user: "User",
  male: "Male",
  female: "Female",
  price_for: "Price For",
  for: "For",
  days: 'Days',
  day: 'Day',
  kwd: 'KWD',
  preview: 'Preview',
  change_password: 'Change Password',
  change: 'Change',
  old_password: 'Old Password',
  new_password: 'New Password',
  suggestions: 'Suggestions', 
  complain: 'Complain'
};
