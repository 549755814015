import { useEffect, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import profile from "../../../../images/icons/profile.svg";
import FeedbackService from "../../../../services/FeedbackService";
import Loader from "../../../common/Loader";
import NoData from "../../../common/NoData";
import { Translate } from "../../../Enums/Tranlate";

const escTabs =['suggestions', 'complain']
const Escalations  = () =>{
    const [escalations, setEscalations] = useState([])
    const [selectedTab, setSelectedTab] = useState('suggestions')
    const [hasData, setHasData] = useState()
    const [loading, setLoading] = useState(false)
    const lang = useSelector(state=> state?.auth.lang)
    const feedbackService = new FeedbackService()

    useEffect(()=>{
        setLoading(true)
        feedbackService.getList(selectedTab).then(res=>{
            if(res?.status === 200){
                if(res?.data?.data?.data.length > 0){
                    setEscalations(res?.data?.data?.data)
                    setHasData(1)
                } else{
                    setHasData(0)
                }
            }
            setLoading(false)
        })
    },[selectedTab])

    return <>
    <Card className="tabs w-50">
        {escTabs?.map((tab, index)=>{
            return <div key={index} 
                    onClick={()=> setSelectedTab(tab)}
                    className={`tab w-50 ${tab === selectedTab ? 'active' : ''}`}>
                {Translate[lang][tab]}
            </div>
        })}
    </Card>

    {(hasData === 1 && !loading) && escalations?.map((escalation, index)=>{
        return <Card key={index} style={{borderRadius: '8px'}} className='escalation-card'>
            <CardBody>
                <h3 className="mb-3">{escalation.name}</h3>
                <div className="head d-flex align-items-center justify-content-between">
                    <div className="profile p-1" style={{border: '1px solid #C2C6D8', borderRadius: '27px'}}>
                        <img src={escalation.user.avatar || profile} alt={escalation.user.full_name} width={40} height={40} style={{borderRadius:'50%'}} />
                        <span className="mx-2">{escalation.user.full_name}</span>
                    </div>
                    <div className="date">
                        <i className="la la-calendar"></i>
                        <span className="mx-1">{escalation.createdAt.split('T')[0]}</span>
                    </div>
                    <Badge
                        style={{cursor: 'pointer'}}
                        variant={selectedTab === 'suggestions' ? 'success' : 'danger'}
                    >
                        {Translate[lang][selectedTab]}
                    </Badge>
                </div>
                <p className="my-3 py-2">{escalation.description}</p>
                {escalation.feedback_images?.length > 0 && <div className="row"> {escalation.feedback_images?.map(feedImg=>{
                    return <div className="col-md-3">
                        <a href={feedImg.url} target="_blank" rel="noreferrer">
                            <img src={feedImg.url} alt={feedImg.id} className='d-block w-100' />
                        </a>
                    </div>
                })}
                </div>}
                {/* <label className="mb-0">{Translate[lang].add} {Translate[lang].reply}</label>
                <div className="w-50">
                    <input 
                        type='text' 
                        name={`reply${index}`} 
                        value={escalation.reply} 
                        className='py-1 px-2'
                        style={{width: '80%', border: '1px solid #F1F5F9', borderRadius: '8px'}}
                        onChange={e=>{
                            let update= escalations?.map((esc,ind)=>{
                                if(ind === index){
                                    return{
                                        ...esc,
                                        reply: e.target.value
                                    }
                                } else{
                                    return esc
                                }
                            })
                            setEscalations(update)
                        }}
                    />
                    {!!escalation.reply && <Button variant="primary" onClick={()=> submitReply()} className="mx-2">{Translate[lang].add}</Button>}
                </div> */}
                
            </CardBody>
        </Card>
    })}

    {loading && (
            <div style={{ height: "300px",textAlign: 'center' }}>
              <Loader />
            </div>
          )}
    {hasData === 0 && <div className="mt-4" style={{textAlign: 'center'}}>
        <NoData />
    </div>}
    </>
}
export default Escalations ;