import { Button, Card, Dropdown, Table } from "react-bootstrap";
import Select from "react-select";
import locationImg from "../../../../images/icons/location.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import profile from "../../../../images/icons/profile.svg";
import "../style.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { setPath, setSearch } from "../../../../store/actions/AuthActions";
import { Translate } from "../../../Enums/Tranlate";
import Loader from "../../../common/Loader";
import NoData from "../../../common/NoData";
import AreasService from "../../../../services/AreasServices";
import Service from "../../../../services/Services";
import Pagination from "../Pagination/Pagination";
import DeleteModal from "../../../common/DeleteModal";

const Services = ({ selectedCategories, tabs, govenrate }) => {
  const [selectedTab, setSelectedTab] = useState("hospital");
  const [loading, setLoading] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [area, setArea] = useState("");
  const [item, setItem] = useState("");
  const [areasOptions, setAreasOptions] = useState([]);
  const [filterParams, setFilterParams] = useState({});
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const lang = useSelector((state) => state.auth?.lang);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const areasService = new AreasService();
  const service = new Service();

  useEffect(() => {
    if (govenrate?.id) {
      areasService.getList(govenrate?.id).then((res) => {
        if (res?.status === 200) {
          let data = res.data.data?.map((item) => {
            return {
              ...item,
              label: lang === "en" ? item.name_en : item.name_ar,
              value: item.id,
            };
          });
          setAreasOptions(data);
          setArea('');
        }
      });
    }
  }, [govenrate]);

  useEffect(() => {
    setFilterParams({
      type: selectedTab,
      governorate_id: govenrate?.id,
      area_id: area?.value,
    });
  }, [selectedTab, govenrate, area]);

  useEffect(()=>{
    if(location?.state?.tab){
      setSelectedTab(location?.state?.tab)
    } else{
      setSelectedTab("hospital")
    }
  },[location.state])

  return (
    <div className="hospital">
      <Card className="my-4 pt-4" style={{ borderRadius: "5px" }}>
        <div className="d-flex pt-3">
          <h4 className="label">{Translate[lang][selectedCategories]}</h4>
          <div className="position-relative w-100 mx-4">
            <img src={locationImg} alt="location" className="location-area" />
            <Select
              value={area}
              name="area"
              className="select"
              placeholder={Translate[lang]?.area}
              options={areasOptions}
              onChange={(e) => setArea(e)}
            />
          </div>
        </div>
        <div className="tabs">
          {tabs?.map((tab, index) => {
            return (
              <p
                key={index}
                onClick={() => {
                  setArea("");
                  setSelectedTab(tab);
                  dispatch(setSearch(''))
                }}
                className={`tab ${tab === selectedTab ? "active" : ""}`}
              >
                {Translate[lang][tab]}
              </p>
            );
          })}
        </div>
      </Card>
      <Card className="mt-3">
        <Card.Body className={`${hasData === 0 && "text-center"} `}>
          <div className="d-flex justify-content-between align-items-center">
            <h4 style={{ fontWeight: "800", fontSize: "18px" }}>
              {Translate[lang][selectedTab]}
            </h4>
            <Button
              variant="primary"
              type="button"
              onClick={() => {
                navigate(`/${govenrate.to}/add`, {
                  state: { ...govenrate, type: 'services', tab: selectedTab, edit_id: '' },
                });
                dispatch(setPath("services"));
              }}
            >
              + {Translate[lang].add} {Translate[lang][selectedTab]}
            </Button>
          </div>
          {loading && (
            <div style={{ height: "300px" }}>
              <Loader />
            </div>
          )}
          {hasData === 1 && !loading && (
            <Table responsive>
              <thead>
                <tr>
                  <th>{Translate[lang].num_of_services}</th>
                  <th>{Translate[lang].area}</th>
                  <th>{Translate[lang].location}</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>

              <tbody className="table-body">
                {data?.map((item, index) => {
                  return (
                    <tr key={index} className="text-center">
                      <td className="profile-section">
                        <img
                          src={profile}
                          alt="profile"
                          width={24}
                          className="mx-3"
                        />
                        {lang === "en" ? item.name_en : item.name_ar}
                      </td>
                      <td>
                        {lang === "en"
                          ? item?.area?.name_en
                          : item?.area?.name_ar}
                      </td>
                      <td>
                        <span>
                        {item.location_url?.slice(0, 35)}{item.location_url?.length > 35 ? '...' : ''}
                        </span>
                      </td>
                      <td>
                        <span
                          className="details"
                          onClick={() =>
                            navigate(
                              `/${govenrate.to}/${selectedTab}/${item.id}`,
                              {
                                state: { ...govenrate, type: 'services', tab: selectedTab },
                              }
                            )
                          }
                        >
                          {Translate[lang].details}
                        </span>
                      </td>
                      <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          // variant="success"
                          className="light sharp i-false"
                        >
                          <i className="la la-ellipsis-v" style={{ fontSize: "27px" }}></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>{
                              navigate(`/${location?.state?.to}/add`, {
                                state: { ...location?.state, type: 'services', from: 'home', edit_id: item.id },
                              });
                              dispatch(setPath('services'));
                            }}
                          >
                            {Translate[lang]?.edit}
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => {
                            setItem(item)
                            setDeleteModal(true)
                          }}>
                            {Translate[lang]?.delete}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      </td>
                      
                    </tr>
                  );
                })}
              </tbody>
              
            </Table>
          )}
          {hasData === 0 && <NoData />}
          <Pagination
            setData={setData}
            service={service}
            setHasData={setHasData}
            setLoading={setLoading}
            shouldUpdate={shouldUpdate}
            filterParams={filterParams}
          />

          {deleteModal && (
            <DeleteModal
              open={deleteModal}
              titleMsg={lang === "en" ? item.name_en : item.name_ar}
              deletedItem={item}
              modelService={service}
              setShouldUpdate={setShouldUpdate}
              onCloseModal={setDeleteModal}
            />
          )}
        </Card.Body>
      </Card>
    </div>
  );
};
export default Services;
