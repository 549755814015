import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, Button, Col, Row, Dropdown } from "react-bootstrap";
import { Translate } from "../../../Enums/Tranlate";
// import Select from 'react-select';
// import location from '../../../../images/icons/location.svg'
import offers from "../../../../images/icons/offers.svg";
// import eventsImg from '../../../../images/events.svg'
import { useEffect, useState } from "react";
import "../style.scss";
import NoData from "../../../common/NoData";
import Loader from "../../../common/Loader";
import { setPath } from "../../../../store/actions/AuthActions";
import Pagination from "../Pagination/Pagination";
// import AreasService from "../../../../services/AreasServices";
import Service from "../../../../services/Services";
import DeleteModal from "../../../common/DeleteModal";

const News = ({ selectedCategories, govenrate }) => {
  const [tab, setTab] = useState("news");
  const tabs = ["news", "offers"];
  // const [area, setArea] = useState('')
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [item, setItem] = useState("");
  const [shouldUpdate, setShouldUpdate] = useState(false);
  // const [areasOptions, setAreasOptions] = useState([])
  const [filterParams, setFilterParams] = useState({});
  const lang = useSelector((state) => state.auth?.lang);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const areasService = new AreasService()
  const service = new Service();

  // useEffect(()=>{
  //     areasService.getList(govenrate?.id).then(res=>{
  //         if(res?.status === 200){
  //             let data = res.data.data?.map(item =>{
  //                 return{
  //                     ...item,
  //                     label: lang === 'en' ? item.name_en : item.name_ar,
  //                     value: item.id
  //                 }
  //             })
  //             setAreasOptions(data)
  //         }
  //     })
  // },[])

  useEffect(() => {
    setFilterParams({
      type: tab,
      governorate_id: govenrate?.id,
    });
  }, [tab, govenrate]);

  return (
    <>
      <Card className="my-4" style={{ borderRadius: "5px" }}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex tabs w-50 mx-2">
            {tabs?.map((t, index) => {
              return (
                <p
                  key={index}
                  onClick={() => setTab(t)}
                  className={`tab ${tab === t ? "active" : ""}`}
                >
                  {Translate[lang][t]}
                </p>
              );
            })}
          </div>

          <Button
            variant="primary"
            type="button"
            className="mx-3"
            onClick={() => {
              navigate(`/${govenrate?.to}/add`, {
                state: { ...govenrate, type: tab },
              });
              dispatch(setPath(tab));
            }}
          >
            + {Translate[lang].add} {Translate[lang][tab]}
          </Button>
        </div>
      </Card>

      {loading && (
        <Card style={{ height: "300px" }}>
          <Loader />
        </Card>
      )}

      {hasData === 1 && (
        <Row className="mt-4 mb-5">
          {data?.map((event, index) => {
            return (
              <Col md={4} key={index} className="custom-card mb-3">
                <Card className="overflow-hidden">
                  <div
                    style={{
                      position: 'absolute', top: '10px',
                      right: lang==='en' ? 'auto' : '10px',
                      left: lang==='en' ? '10px' : 'auto',
                  }}>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        className="light sharp i-false"
                      >
                        <i className="la la-ellipsis-v" style={{ fontSize: "27px" }}></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>{
                            navigate(`/${govenrate?.to}/add`, {
                              state: { ...govenrate, edit_id: event.id, type: tab, tab: tab },
                            });
                            dispatch(setPath(tab));
                          }}
                        >
                          {Translate[lang]?.edit}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                          setItem(event)
                          setDeleteModal(true)
                        }}>
                          {Translate[lang]?.delete}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <img
                    src={event.images[0]?.url}
                    alt={event.name_en}
                    className="w-100"
                  />
                  <div className="custom-card-body">
                    <p className="name">
                      {lang === "en" ? event.name_en : event.name_ar}
                    </p>
                    <p className="location">
                      {tab === "news" ? (
                        <i
                          className="la la-calendar"
                          style={{ fontSize: "18px" }}
                        />
                      ) : (
                        <img src={offers} alt="offers" width={16} />
                      )}
                      {tab === "news"
                        ? event.date?.split("T")[0]
                        : event.location_url}
                    </p>
                    <p className="desc">
                      {lang === "en"
                        ? event.description_en
                        : event.description_ar}
                    </p>
                    <button
                      className="view"
                      onClick={() =>
                        navigate(`/${govenrate?.to}/${tab}/${event?.id}`, {
                          state: { ...govenrate, type: tab },
                        })
                      }
                    >
                      {Translate[lang].view_details}
                    </button>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      )}

      {hasData === 0 && (
        <Card>
          <Card.Body className="text-center">
            <NoData />
          </Card.Body>
        </Card>
      )}

      <Pagination
        setData={setData}
        service={service}
        setHasData={setHasData}
        setLoading={setLoading}
        filterParams={filterParams}
        shouldUpdate={shouldUpdate}
      />

      {deleteModal && (
            <DeleteModal
              open={deleteModal}
              titleMsg={lang === "en" ? item.name_en : item.name_ar}
              deletedItem={item}
              modelService={service}
              setShouldUpdate={setShouldUpdate}
              onCloseModal={setDeleteModal}
            />
          )}
    </>
  );
};
export default News;
