import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
/// Image
import profile from "../../../images/icons/profile.svg";
import { Dropdown } from "react-bootstrap";
// import Logout from '../../pages/Authintication/Logout';
import { useDispatch, useSelector } from "react-redux";
import { setLang, setSearch } from "../../../store/actions/AuthActions";
import { Translate } from "../../Enums/Tranlate";
import NavHader from "./NavHader";

const Header = ({ onNote }) => {
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.auth.lang);
  const search = useSelector((state) => state.auth.search);
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var finalName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;

  useEffect(()=>{
    if(!search){
      setSearchText("")
    }
  }, [search])

  const handleSearch = (e) =>{
    e.preventDefault()
    dispatch(setSearch(searchText))
  }

  return (
    <>
      <div className="header">
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left">
                <NavHader lang={lang} />
                <div
                  className="dashboard_bar"
                  style={{ textTransform: "capitalize" }}
                >
                  {finalName.join(" ").length === 0
                    ? Translate[lang]?.dashboard
                    : Translate[lang][finalName.join("_")]}
                </div>
              </div>
              <ul className="navbar-nav header-right p-0">
                <form className="position-relative d-none d-md-block">
                  <input
                    type="text"
                    placeholder={Translate[lang].search_here}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    style={{
                      padding: "10px 24px",
                      border: "none",
                      borderRadius: "8px",
                      width: "346px",
                      marginTop: "18px",
                    }}
                  />
                  <button 
                    className="position-absolute"
                    onClick={handleSearch} 
                    type='submit' 
                    style={{
                      top: "50%", border: 'none', background: 'none',
                      right: lang === "en" ? "8px" : "auto",
                      left: lang === "ar" ? "8px" : "auto",
                      transform: "translate(0, -50%)",
                      fontSize: "20px",
                    }}
                  >
                      <i className="la la-search"></i>
                    </button>
                </form>

                <Dropdown
                  as="li"
                  className="nav-item dropdown notification_dropdown "
                >
                  <Dropdown.Toggle
                    variant=""
                    className="nav-link  ai-icon i-false"
                    href="#"
                    role="button"
                    data-toggle="dropdown"
                  >
                    <i className="la la-language"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu align="right" className="mt-2">
                    <ul className="timeline text-center">
                      <li
                        className="py-2"
                        style={{
                          backgroundColor:
                            lang === "en" ? "var(--bg-primary)" : "#fff",
                          color: lang === "en" ? "var(--primary)" : "#7e7e7e",
                          cursor: "pointer",
                        }}
                        onClick={() => dispatch(setLang("en"))}
                      >
                        English
                      </li>
                      <li
                        className="py-2"
                        style={{
                          backgroundColor:
                            lang === "ar" ? "var(--bg-primary)" : "#fff",
                          color: lang === "ar" ? "var(--primary)" : "#7e7e7e",
                          cursor: "pointer",
                        }}
                        onClick={() => dispatch(setLang("ar"))}
                      >
                        اللغه العربيه
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown as="li" className="nav-item dropdown header-profile">
                  <Dropdown.Toggle
                    variant=""
                    as="a"
                    className="nav-link i-false"
                  >
                    <img src={profile} width={20} alt="profile" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu align="right" className="mt-2 ">
                    <Link to="/profile" className="dropdown-item ai-icon">
                      <svg
                        id="icon-user1"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-primary"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                        <circle cx={12} cy={7} r={4} />
                      </svg>
                      <span className={lang === "en" ? "ml-2" : "mr-2"}>
                        {Translate[lang]?.profile}{" "}
                      </span>
                    </Link>
                    {/* <Logout /> */}
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
