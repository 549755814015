import React, {useEffect, useState } from "react";
import { Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Translate } from "../../Enums/Tranlate";
import Loader from "../../common/Loader";
import profile from '../../../images/icons/profile.svg'
import './style.scss'
import Pagination from "../../common/Pagination/Pagination";
import NoData from "../../common/NoData";
import AdsService from "../../../services/AdsServices";
import { useNavigate } from "react-router-dom";

const AdScreen = () =>{
    const adsService = new AdsService()
    const [hasData, setHasData] =useState(1)
    const [loading, setLoading] = useState(false)
    const [tabs, setTabs] = useState(['admin_ads', 'users_ads'])
    const [selectedTab, setSelectedTab] = useState('admin_ads')
    const [formData, setFormData] = useState([])
    const [params, setParams] = useState({})
    const Auth = useSelector(state=> state.auth?.auth)
    const governate = useSelector(state=> state.auth.governate)
    const lang = useSelector(state=> state.auth?.lang)
    const isExist = (data)=> Auth?.admin?.admin_roles?.includes(data)
    const navigate = useNavigate()

    useEffect(()=>{
        let param = {
            isAdmin: selectedTab === 'admin_ads'
        }
        setParams(param)
    }, [selectedTab])

    const changeStatusToggle = ()=> {}

    return(<div className="ads">
        <div className="d-flex align-items-center" style={{justifyContent: 'space-between'}}>
            <div className="tabs">
                {tabs?.map((tab, index)=> {
                        return <p key={index} 
                                    onClick={()=> setSelectedTab(tab)}
                                    className={`tab ${tab === selectedTab ? 'active' : ''}`}>
                            {Translate[lang][tab]}
                        </p>
                })}
            </div>
            <div style={{marginTop:'32px'}}>
            <Dropdown>
            <Dropdown.Toggle
              variant="success"
              className="light sharp i-false"
            >
              <i className="la la-cog" style={{ fontSize: "27px" }}></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => navigate('/ads/add')}
              >
                {Translate[lang]?.add} {Translate[lang]?.ads}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate('/plans')}>
                {Translate[lang]?.add} {Translate[lang]?.plan}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </div>

        </div>

        {loading && <Card className="py-5" style={{height: '300px', borderRadius: '8px'}}>
            <Card.Body>
                <Loader />
            </Card.Body>
        </Card>}

        {(hasData === 1 && !loading) &&<Row className="mt-4">
            {formData?.map((data,index)=>{
                return <Col md={4} key={index} className='mb-3'>
                    <div className='custom'>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="user d-flex">
                                <img src={profile} width={40} alt='profile' />
                                <div>
                                    <p class='name'>{data.user.name}</p>
                                    <p class='phone'>
                                        <i className="la la-phone"></i>
                                        {data.user.phone}
                                    </p>
                                </div>
                            </div>
                            {/* <p class='hour m-0'>{data.hours} {Translate[lang].hour}</p> */}
                            <Form.Check
                                type="switch"
                                id={`custom-switch${index}`}
                                checked={data.status || true}
                                onChange={(e)=> changeStatusToggle(e)}
                            />
                        </div>
                        <p className="name_of_ad">{data.name}</p>
                        <p className="type">{Translate[lang][data.category]}</p>
                        <p className="description">
                            <label>{Translate[lang].description}:</label>
                            {data.description}
                        </p>
                        <p className="area">
                            <label>{Translate[lang].area}:</label>
                            {(lang==='en' && governate?.filter(res=> res.id === data.governorate_id).length > 0) ? governate?.filter(res=> res.id === data.governorate_id)[0]?.name_en : governate?.filter(res=> res.id === data.governorate_id)[0]?.name_ar}
                        </p>
                        <p className="plan">
                            <label>{Translate[lang].plan}:</label>
                            {Translate[lang][data.ads_price.type]}
                        </p>
                        <p className="duration">
                            <label>{Translate[lang].duration}:</label>
                            {data.ads_plan.duration} {data.ads_plan.duration <= 7 ? Translate[lang].days : Translate[lang].day}
                        </p>
                    </div>
                </Col>
            })}
        </Row>}
{console.log(hasData,loading, formData)}
        {(hasData === 0 && !loading) && <Card className="py-5" style={{borderRadius: '8px'}}>
            <Card.Body className="text-center">
                <NoData />
            </Card.Body>
        </Card>}

        <Pagination
            setData={setFormData}
            service={adsService}
            allParams={params}
            setHasData={setHasData}
            setLoading={setLoading}
        />
    </div>)
}
export default AdScreen;