import { AvField, AvForm } from "availity-reactstrap-validation";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Translate } from "../../../Enums/Tranlate";
import visa1 from '../../../../images/icons/visa1.svg'
import visa2 from '../../../../images/icons/visa2.svg'
import visa3 from '../../../../images/icons/visa3.svg'
import { Form, Button } from "react-bootstrap";
import Select from 'react-select';
import IbanService from "../../../../services/IbanService";
import { toast } from "react-toastify";
import Loader from "../../../common/Loader";

const Payments = ()=> {
    const [formData, setFormData] = useState({
        area: '',
        iban: ''
    })
    const [areasOptions, setAreasOptions] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingPage, setLoadingPage] = useState(false)
    const [isAdded, setIsAdded] = useState(false)
    const lang = useSelector(state=> state.auth?.lang)
    const payments= [
        {img: visa1, name: 'Visa Name', status: true},
        {img: visa2, name: 'Visa Name', status: false},
        {img: visa3, name: 'Visa Name', status: true},
    ]

    useEffect(()=>{
        setLoadingPage(true)
        new IbanService().getList().then(res=>{
            if(res && res.status === 200){
                if(!!res.data.data) {
                    setFormData({...formData, iban: res.data.data?.iban})
                    setIsAdded(true)
                }
            }
            setLoadingPage(false)
        })
    },[])

    const onSubmit = (e)=>{
        e.preventDefault();
        setLoading(true)
        let data ={
            iban: formData.iban
        }
        new IbanService().create(data).then(res=>{
            if(res?.status === 201){
                toast.success('Iban Updated Successfully')
            }
            setLoading(false)
        })
    }

    const inputHandler = (e) =>{
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const deleteIban = () =>{
        new IbanService().remove().then(res=>{
            if(res?.status === 200){
                toast.success('Iban Removed Successfully')
                setFormData({...formData, iban: ''})
            }
            setLoading(false)
        })
    }

    if(loadingPage){
        return <Card className="py-5" style={{height: '350px'}}>
            <CardBody>
                <Loader />
            </CardBody>
      </Card>
    }

    return <Card style={{borderRadius: '8px'}}>
        <CardBody>
            <Row>
                <Col md={8} sm={12}>
                    <AvForm onValidSubmit={onSubmit}>
                        <div className="d-flex" style={{gap: '12px'}}>
                            <div style={{width: '90%'}}>
                                <AvField
                                    label ={`${Translate[lang]?.iban}`}
                                    name ='iban'
                                    type='text'
                                    value={formData?.iban}
                                    validate={{
                                        required: {
                                            value:true,
                                            errorMessage: Translate[lang].field_required
                                        },
                                    }}
                                    placeholder={`${Translate[lang]?.iban}`}
                                    onChange={(e)=> inputHandler(e)}
                                />
                            </div>
                            <button 
                                className="p-2 btn btn-danger"
                                style={{height: '38px',marginTop: '32px'}}
                                type='button'
                                onClick={deleteIban}
                            >
                                <i className="la la-close" style={{fontSize: '18px'}}></i>
                            </button>
                        </div>
                    
                        {/* <h3 style={{fontSize: '22px', marginTop: '40px', marginBottom: '24px'}}>{Translate[lang].payment_activation}</h3>
                        <div>
                            <div className="d-flex" style={{borderBottom: '1px solid #E2E2E2'}}>
                                <p className="mb-2 w-75" style={{fontSize: '14px'}}>
                                    {Translate[lang].payment_method}
                                </p>
                                <p className="mb-2 w-25" style={{fontSize: '14px'}}>
                                    {Translate[lang].status}
                                </p>
                            </div>
                            {payments?.map((payment, index)=>{
                                return <div key={index} className='d-flex mt-4'>
                                    <div className=" d-flex mb-2 w-75" style={{gap: '22px'}}>
                                        <img src={payment.img} alt={payment.name} />
                                        <h4 style={{fontSize: '16px'}} className="mb-0">{payment.name}</h4>
                                    </div>
                                    <div className="mb-2 w-25">
                                        <Form.Check
                                            type="switch"
                                            id={`status${index}`}
                                            checked={payment.status}
                                            onChange={(e)=> {}}
                                        />
                                    </div>
                                </div>
                            })}
                        </div>
                        <h3 style={{fontSize: '22px', marginTop: '40px', marginBottom: '12px'}}>{Translate[lang].currency}</h3>
                        <Select
                            value={formData.area}
                            name="area"
                            className="w-50"
                            placeholder={Translate[lang]?.area}
                            options={areasOptions}
                            onChange={(e)=> inputHandler(e)}
                        /> */}
                        <div className="mt-4">
                            <Button type="submit" variant="primary" disabled={loading}>
                                {isAdded ? Translate[lang].edit : Translate[lang].save}
                            </Button>
                        </div>
                    </AvForm>
                </Col>
            </Row>
        </CardBody>
    </Card>
}
export default Payments;