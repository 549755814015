import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, Button, Col, Row, Dropdown } from "react-bootstrap";
import { Translate } from "../../../Enums/Tranlate";
import Select from "react-select";
import location from "../../../../images/icons/location.svg";
import user from "../../../../images/user.svg";
import { useEffect, useState } from "react";
import "../style.scss";
import NoData from "../../../common/NoData";
import Loader from "../../../common/Loader";
import Service from "../../../../services/Services";
import AreasService from "../../../../services/AreasServices";
import Pagination from "../Pagination/Pagination";
import { setPath } from "../../../../store/actions/AuthActions";
import DeleteModal from "../../../common/DeleteModal";

const Deaths = ({ selectedCategories, govenrate }) => {
  const [area, setArea] = useState("");
  const [deaths, setDeaths] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [areasOptions, setAreasOptions] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [item, setItem] = useState("");
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const lang = useSelector((state) => state.auth?.lang);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const areasService = new AreasService();
  const service = new Service();
  const [filterParams, setFilterParams] = useState({});

  useEffect(() => {
    areasService.getList(govenrate?.id).then((res) => {
      if (res?.status === 200) {
        let data = res.data.data?.map((item) => {
          return {
            ...item,
            label: lang === "en" ? item.name_en : item.name_ar,
            value: item.id,
          };
        });
        setAreasOptions(data);
      }
    });

    setFilterParams({
      type: "deaths",
      governorate_id: govenrate.id,
      area_id: area.value,
    });
  }, [govenrate, area]);

  return (
    <>
      <Card
        className="my-4 pb-3"
        style={{ borderRadius: "5px", paddingTop: "40px" }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <h4 className="label mb-0">
              {Translate[lang][selectedCategories]}
            </h4>
            <div className="position-relative mx-4">
              <img src={location} alt="location" className="location-area" />
              <Select
                value={area}
                name="area"
                className="select"
                placeholder={Translate[lang]?.area}
                options={areasOptions}
                onChange={(e) => setArea(e)}
                styles={{ width: "10rem" }}
              />
            </div>
          </div>

          <Button
            variant="primary"
            type="button"
            className="mx-3"
            onClick={() => {
              navigate(`/${govenrate?.to}/add`, {
                state: { ...govenrate, type: "deaths", edit_id: '' },
              });
              // dispatch(setPath(selectedCategories))
            }}
          >
            + {Translate[lang].add} {Translate[lang][selectedCategories]}
          </Button>
        </div>
      </Card>

      {hasData === 1 && (
        <Row className="mt-4 mb-5">
          {deaths?.map((death, index) => {
            return (
              <Col md={3} key={index} className="death-card mb-4">
                <Card className="overflow-hidden">
                  <div
                    style={{
                      position: 'absolute', top: '10px',
                      right: lang==='en' ? 'auto' : '10px',
                      left: lang==='en' ? '10px' : 'auto',
                  }}>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        className="light sharp i-false"
                      >
                        <i className="la la-ellipsis-v" style={{ fontSize: "27px" }}></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>{
                            navigate(`/${govenrate?.to}/add`, {
                              state: { ...govenrate, edit_id: death.id, type: "deaths", tab: '' },
                            });
                            dispatch(setPath('deaths'));
                          }}
                        >
                          {Translate[lang]?.edit}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                          setItem(death)
                          setDeleteModal(true)
                        }}>
                          {Translate[lang]?.delete}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="death-card-body">
                    <img src={death?.images[0]?.url} alt={death?.name_en} />
                    <p className="name">
                      {lang === "en" ? death.name_en : death.name_ar}
                    </p>
                    <p className="date">{death.date?.split("T")[0]}</p>
                    <p className="governate">
                      {lang === "en"
                        ? death?.governorate?.name_en
                        : death?.governorate?.name_ar}
                    </p>
                    <p className="area">
                      {lang === "en"
                        ? death?.area?.name_en
                        : death?.area?.name_ar}
                    </p>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      )}

      {loading && (
        <Card style={{ height: "300px" }}>
          <Loader />
        </Card>
      )}

      {hasData === 0 && (
        <Card className="mt-3">
          <Card.Body className="text-center">
            <NoData />
          </Card.Body>
        </Card>
      )}

      <Pagination
        setData={setDeaths}
        service={service}
        setHasData={setHasData}
        setLoading={setLoading}
        filterParams={filterParams}
        shouldUpdate={shouldUpdate}
      />

      {deleteModal && (
            <DeleteModal
              open={deleteModal}
              titleMsg={lang === "en" ? item.name_en : item.name_ar}
              deletedItem={item}
              modelService={service}
              setShouldUpdate={setShouldUpdate}
              onCloseModal={setDeleteModal}
            />
          )}
    </>
  );
};
export default Deaths;
