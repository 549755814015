import React, { useState } from "react";

export function  NavMenuToggle(){
	setTimeout(()=>{	
		let mainwrapper = document.querySelector("#main-wrapper");
		if(mainwrapper.classList.contains('menu-toggle')){
			mainwrapper.classList.remove("menu-toggle");
		}else{
			mainwrapper.classList.add("menu-toggle");
		}
	},200);
}

const NavHader = (lang) => {
   const [toggle, setToggle] = useState(false);

   return (
      <div className="nav-header" style={{
         right: (toggle && lang?.lang === 'ar') ? '220px' : 'auto',
         left: (toggle && lang?.lang === 'en') ? '220px' : 'auto',
      }}>
         <div className="nav-control" 
            onClick={() => {
               setToggle(!toggle)
               NavMenuToggle()
            }
            }
         
         >
            <div className={`hamburger ${toggle ? "is-active" : ""}`}>
               <span className="line"></span>
               <span className="line"></span>
               <span className="line"></span>
            </div>
         </div>
      </div>
   );
};

export default NavHader;
