import dashboardWhite from '../../images/icons/dashboard-white.svg'
import dashboardBlack from '../../images/icons/dashboard-black.svg'
import governateWhite from '../../images/icons/governate-white.svg'
import governateBlack from '../../images/icons/governate-black.svg'
import userWhite from '../../images/icons/user-white.svg'
import userBlack from '../../images/icons/user-black.svg'
import adminWhite from '../../images/icons/admin-white.svg'
import adminBlack from '../../images/icons/admin-black.svg'
import adsWhite from '../../images/icons/ads-white.svg'
import adsBlack from '../../images/icons/ads-black.svg'
import notificationWhite from '../../images/icons/notification-white.svg'
import notificationBlack from '../../images/icons/notification-black.svg'
import helpWhite from '../../images/icons/help-white.svg'
import helpBlack from '../../images/icons/help-black.svg'
import bannerWhite from '../../images/icons/banner-white.svg'
import bannerBlack from '../../images/icons/banner-black.svg'

export const MenuList = [
    //Dashboard
    {   
        title:'Dashboard',
        classsChange: 'mm-collapse',
        whiteImage: <img src={dashboardWhite} alt='dashboard' />,
        blackImage: <img src={dashboardBlack} alt='dashboard' />,
        to: 'dashboard',
        text: 'dashboard',
    },
    {
        title:'Governorate',
        text:'governorate',
        classsChange: 'mm-collapse',
        whiteImage: <img src={governateWhite} alt='Govenrate' />,
        blackImage: <img src={governateBlack} alt='Govenrate' />,
        content: [],
        // content : [
        //     {
        //         title:'Capital Govenrate',
        //         text:'capital_govenrate',
        //         to: 'govenrate',
        //     },
        //     {
        //         title:'Hawalli Govenrate',
        //         text:'hawalli_govenrate',
        //         to: 'govenrate',
        //     },
        //     {
        //         title:'Mubarak Al-Kabeer Govenrate',
        //         text: 'mubarak_al_Kabeer_govenrate',
        //         to: 'govenrate',
        //     },
        //     {
        //         title:'Ahmadi Govenrate',
        //         text: 'ahmadi_govenrate',
        //         to: 'govenrate',
        //     },
        //     {
        //         title:'Farwaniya Govenrate',
        //         text: 'farwaniya_govenrate',
        //         to: 'govenrate',
        //     },
        //     {
        //         title:'Jahra Govenrate',
        //         text: 'jahra_govenrate',
        //         to: 'govenrate',
        //     },
        // ]
    },
    //All Users
    {   
        title:'All Users',
        classsChange: 'mm-collapse',
        whiteImage: <img src={userWhite} alt='Users' />,
        blackImage: <img src={userBlack} alt='Users' />,
        to: 'users',
        text: 'users',
    },
    //Admin
    {   
        title:'Admin Panal',
        classsChange: 'mm-collapse',
        whiteImage: <img src={adminWhite} alt='Admin' />,
        blackImage: <img src={adminBlack} alt='Admin' />,
        to: 'admins',
        text: 'admins',
    },
    //Ads
    {   
        title:'Ads',
        classsChange: 'mm-collapse',
        whiteImage: <img src={adsWhite} alt='Ads' />,
        blackImage: <img src={adsBlack} alt='Ads' />,
        to: 'ads',
        text: 'ads',
    },
    // Notification
    {   
        title:'Notification',
        classsChange: 'mm-collapse',
        whiteImage: <img src={notificationWhite} alt='Notification' />,
        blackImage: <img src={notificationBlack} alt='Notification' />,
        to: 'notification',
        text: 'notification',
    },
    //Banners
    {   
        title:'Banners',
        classsChange: 'mm-collapse',
        whiteImage: <img src={bannerWhite} alt='banners' />,
        blackImage: <img src={bannerBlack} alt='banners' />,
        to: 'banners',
        text: 'banners',
    },
    //AdScreen
    {   
        title:'AdScreen',
        classsChange: 'mm-collapse',
        whiteImage: <img src={adsWhite} alt='Ads' />,
        blackImage: <img src={adsBlack} alt='Ads' />,
        to: 'ad-screen',
        text: 'ad_screen',
    },
    //Help Center
    {   
        title:'Help Center',
        classsChange: 'mm-collapse',
        whiteImage: <img src={helpWhite} alt='Help' />,
        blackImage: <img src={helpBlack} alt='Help' />,
        to: 'help-center',
        text: 'help_center',
    },
    
]