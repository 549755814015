import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { Translate } from "../../Enums/Tranlate";
import Select from "react-select";
import { useEffect, useState } from "react";
import './style.scss'
import PlanService from "../../../services/PlanServices";
import { toast } from "react-toastify";
import NoDataImg from '../../../images/no-data.png'
import Loader from "../../common/Loader";

const Plans = () =>{
    let intialData ={
        duration: '',
        area: '',
        governorate: '',
        all_governorate: ''
    }
    const [formData, setFormData] = useState(intialData)
    const [shouldUpdate, setShouldUpdate] = useState(false)
    const [loading, setLoading] = useState(false)
    const [hasData, setHasData] = useState(0)
    const [data, setData] = useState([])
    const planService = new PlanService()
    const lang = useSelector(state=> state.auth?.lang)
    const plansDuration = [
        {label: lang === 'en' ? '3 Days' : '٣ أيام', value: 3},
        {label: lang === 'en' ? '7 Days' : '٧ أيام', value: 7},
        {label: lang === 'en' ? '14 Days' : '١٤ يوم', value: 14},
        {label: lang === 'en' ? '21 Days' : '٢١ يوم', value: 21},
        {label: lang === 'en'? '30 Days' : '٣٠ يوم', value: 30},
    ]

    const inputHandler = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(()=>{
        setLoading(true)
        planService?.getList().then(res=>{
            if(res?.status === 200){
                if(res?.data?.data?.length > 0){
                    setData(res?.data?.data)
                    setHasData(1)
                } else{
                    setHasData(2)
                }
                setLoading(false)
            }
        })
    }, [shouldUpdate])

    const handleSubmit = () =>{
        if(!formData?.duration){
            toast.error('Select Duration First')
            return
        }
        if(!formData?.area && !formData?.governorate && !formData?.all_governorate){
            toast.error('Enter Price First')
            return
        }
        let data = {
            duration: formData?.duration?.value,
            prices: []
        }
        if(!!formData?.area) data?.prices?.push({type: 'area', price: formData?.area})
        if(!!formData?.governorate) data?.prices?.push({type: 'governorate', price: formData?.area})
        if(!!formData?.all_governorate) data?.prices?.push({type: 'all_governorate', price: formData?.area})
        planService.create(data)?.then(res=>{
            if(res?.status === 201){
                toast.success(`${Translate[lang].added} ${Translate[lang].plan} ${Translate[lang].successfully}`)
                setFormData(intialData)
                setShouldUpdate(prev=> !prev)
            }
        })
    }

    const deletePlan = (id) =>{
        planService?.remove(id)?.then(res=>{
            if(res?.status === 200){
                toast.success(`${Translate[lang].deleted} ${Translate[lang].plan} ${Translate[lang].successfully}`)
                setShouldUpdate(prev=> !prev)
            }
        })
    }

    return <Row>
        <Col md={8} className='plans'>
            <Card className="py-4">
                <CardBody>
                    <h4>{Translate[lang].add} {Translate[lang].plans}</h4>
                    <Row className="mt-3">
                        <Col md={12}>
                            <label className="text-label">
                                {Translate[lang]?.duration}
                            </label>
                            <Select
                                value={formData?.duration}
                                name="country_code"
                                className="w-50"
                                placeholder={Translate[lang]?.select}
                                options={plansDuration}
                                onChange={(e) => setFormData({ ...formData, duration: e })}
                            />
                        </Col>
                        <Col md={12} className='mt-4'>
                            <label className="text-label mb-0">{Translate[lang]?.price_for}</label>
                        </Col>
                        <Col md={12} className='mt-2'>
                            <div className="add-plans">
                                <div  style={{
                                    width: '132px',
                                    padding: '8px 16px',
                                    borderLeft: lang==='ar' ? '1px solid #dedede' : 'none',
                                    borderRight: lang==='en' ? '1px solid #dedede' : 'none',
                                    marginLeft: lang==='ar' ? '16px' : 'auto',
                                    marginRight: lang==='en' ? '16px' : 'auto',
                                }}>{Translate[lang]?.area}</div>
                                <input
                                    name="area"
                                    type="number"
                                    className="plans-input"
                                    value={formData?.area}
                                    placeholder={`KWD`}
                                    onChange={(e) => inputHandler(e)}
                                />
                            </div>
                        </Col>
                        <Col md={12} className='mt-2'>
                            <div className="add-plans">
                                <span  style={{
                                    padding: '8px 16px',
                                    width: '132px',
                                    borderLeft: lang==='ar' ? '1px solid #dedede' : 'none',
                                    borderRight: lang==='en' ? '1px solid #dedede' : 'none',
                                    marginLeft: lang==='ar' ? '16px' : 'auto',
                                    marginRight: lang==='en' ? '16px' : 'auto',
                                }}>{Translate[lang]?.governorate}</span>
                                <input
                                    name="governorate"
                                    type="number"
                                    className="plans-input"
                                    value={formData?.governorate}
                                    placeholder={`KWD`}
                                    onChange={(e) => inputHandler(e)}
                                />
                            </div>
                        </Col>
                        <Col md={12} className='mt-2'>
                            <div className="add-plans">
                                <span  style={{
                                    width: '132px',
                                    padding: '8px 16px',
                                    borderLeft: lang==='ar' ? '1px solid #dedede' : 'none',
                                    borderRight: lang==='en' ? '1px solid #dedede' : 'none',
                                    marginLeft: lang==='ar' ? '16px' : 'auto',
                                    marginRight: lang==='en' ? '16px' : 'auto',
                                }}>{Translate[lang]?.all_governorate}</span>
                                <input
                                    name="all_governorate"
                                    type="number"
                                    className="plans-input"
                                    value={formData?.all_governorate}
                                    placeholder={`KWD`}
                                    onChange={(e) => inputHandler(e)}
                                />
                            </div>
                        </Col>
                        <Col md={12} className='mt-4'>
                            <Button onClick={handleSubmit}>{Translate[lang].save}</Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
        <Col md={4}>
            <Card className="py-4" style={{ height: '75vh', overflow: 'scroll' }}>
                <CardBody>
                    <h4>{Translate[lang].view} {Translate[lang].plans}</h4>

                    <Row className="mt-2">
                        {loading && <div style={{height: '300px'}}>
                            <Loader />
                        </div>}

                        {hasData === 2 && <img src={NoDataImg} className='mt-5 w-100' alt='no data' />}

                        {hasData === 1 && data?.map(plan=>{
                            return <Col md={12} key={plan.id} className="mt-4">
                            <div className="d-flex justify-content-between">
                                <span>{Translate[lang]?.for} {plan?.duration} {plan?.duration >= 14 ? Translate[lang].day : Translate[lang].days}</span>
                                <div>
                                    <i className="la la-trash cursor-pointer" style={{fontSize: '1.4rem'}}
                                        onClick={()=> deletePlan(plan.id)} ></i>
                                    {/* <i className="la la-edit" style={{fontSize: '1.4rem'}}></i> */}
                                </div>
                            </div>
                            <div className="mt-3">
                                {plan?.ads_prices?.map(ad=>{
                                    return <div 
                                        key={ad.id} 
                                        style={{
                                            background: '#F5F5F5',
                                            padding: '8px 18px',
                                            borderRadius: '12px'
                                        }}
                                        className='d-flex justify-content-between mb-3'>
                                        <span className="mb-0">{Translate[lang][ad.type]} :</span>
                                        <span className="text-black">{ad.price} {Translate[lang].kwd}</span>
                                    </div>
                                })}
                                </div>
                            </Col>
                        })}
                    </Row>
                </CardBody>
            </Card>
        </Col>
    </Row>
}
export default Plans;