import { useState } from "react"
import { useSelector } from "react-redux"
import { Card } from "reactstrap"
import { Translate } from "../../Enums/Tranlate"
import Account from "./Account"
import Escalations from "./Escalations "
import Payments from "./Payments"
import SocialMedia from "./SocialMedia"
import StaticPages from "./StaticPages"
import './style.scss'

const HelpCenter = () =>{
    const tabs = ['account', 'pages', 'social_media', 'escalations', 'payments']
    const [selectedTab, setSelectedTab] = useState('account')
    const lang = useSelector(state=> state?.auth.lang)

    return <>
        <Card className="tabs">
            {tabs?.map((tab, index)=>{
                return <div key={index} 
                        onClick={()=> setSelectedTab(tab)}
                        className={`tab ${tab === selectedTab ? 'active' : ''}`}>
                    {Translate[lang][tab]}
                </div>
            })}
        </Card>

        {selectedTab === 'account' && <Account />}
        {selectedTab === 'social_media' && <SocialMedia />}
        {selectedTab === 'payments' && <Payments />}
        {selectedTab === 'escalations' && <Escalations />}
        {selectedTab === 'pages' && <StaticPages />}
    </>
}
export default HelpCenter