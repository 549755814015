import React from "react";
/// React router dom
import { Routes, Route, Outlet } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";

/// Pages
// import Login from './pages/Authintication/Login'
import Error404 from "./common/Error404";

//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";
import Admins from "./pages/Admin";
import AddAdmin from "./pages/Admin/AddAdmin";
import Users from "./pages/Users";
import Home from "./pages/Dashboard";
import AdScreen from "./pages/AdScreen";
import SocialMedia from "./pages/HelpCenter/SocialMedia";
import Permission from "./pages/Rules";
import Notification from "./pages/Notification";
import Profile from "./pages/Profile";
import Control from "./pages/Control";
import UserProfile from "./pages/Users/Profile";
import StaticPages from "./pages/HelpCenter/StaticPages";
import AddNotification from "./pages/Notification/AddNotification";
import Static from "./pages/HelpCenter/StaticPages/Static";
import Govenrate from "./pages/Govenrate";
import AddForm from "./pages/Govenrate/AddForm";
import HelpCenter from "./pages/HelpCenter";
import Details from "./pages/Govenrate/Details";
import Plans from "./pages/Plans";
import AddAds from "./pages/AdScreen/AddAds";
import Banners from "./pages/Banners";
import AdScreen2 from "./pages/AdScreen2";

const Markup = (props) => {
  // let path = window.location.pathname
  // path = path.split('/')
  // path = path[path.length - 1]
  // let pagePath = path.split('-').includes('page')
  // const [activeEvent, setActiveEvent] = useState(!path)

  const allroutes = [
    /// Dashboard
    { url: "", component: <Home /> },
    { url: "dashboard", component: <Home /> },

    // { url: "govenrate", component: <Govenrate /> },
    { url: "/:govenrate", component: <Govenrate /> },
    { url: "/:govenrate/add", component: <AddForm /> },
    { url: "/:govenrate/:type/:id", component: <Details /> },

    // Admins
    { url: "admins", component: <Admins /> },
    { url: "admins/add-admins", component: <AddAdmin /> },
    { url: "admins/edit-admin/:id/:name", component: <AddAdmin /> },

    // Users
    { url: "users", component: <Users /> },
    { url: "users/profile", component: <UserProfile /> },

    // Rules
    { url: "rules", component: <Permission /> },
    { url: "rules/:id", component: <Permission /> },

    // Ad Screen
    { url: "ads", component: <AdScreen /> },
    { url: "plans", component: <Plans /> },
    { url: "ads/add", component: <AddAds /> },

    // Notification
    { url: "notification", component: <Notification /> },
    { url: "notification/add-notification", component: <AddNotification /> },

    //Setting
    { url: "social", component: <SocialMedia /> },
    { url: "control", component: <Control /> },
    { url: "pages", component: <StaticPages /> },
    { url: "pages/about", component: <Static /> },
    { url: "pages/privacy", component: <Static /> },
    { url: "pages/faqs", component: <Static /> },

    //Profile
    { url: "profile", component: <Profile /> },
    
    //Banners
    { url: "banners", component: <Banners /> },

    //AdScreen
    { url: "ad-screen", component: <AdScreen2 /> },

    //Profile
    { url: "help-center", component: <HelpCenter /> },

    // Error
    { url: "*", component: <Error404 /> },
  ];

  return (
    <>
      <Routes>
        <Route path="page-error-404" element={<Error404 />} />
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  return (
    <div id="main-wrapper" className={`show `}>
      <Nav />
      <div
        className="content-body"
        style={{ minHeight: window.screen.height - 45 }}
      >
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Markup;
