import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import AdminService from "../../../services/AdminService";
import './style.scss'
import { Translate } from "../../Enums/Tranlate";
import { CardBody } from "reactstrap";
import basket from '../../../images/icons/basket.svg'
import Chart from "react-apexcharts";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: 'Jan', uv: 4000,
  },
  {
    name: 'Feb', uv: 3000,
  },
  {
    name: 'Mar', uv: 2000,
  },
  {
    name: 'Apr', uv: 2780,
  },
  {
    name: 'May', uv: 1890,
  },
  {
    name: 'June', uv: 2390,
  },
  {
    name: 'July', uv: 3490
  },
  {
    name: 'Aug', uv: 3490
  },
  {
    name: 'Sep', uv: 3490
  },
  {
    name: 'Oct', uv: 1490
  },
  {
    name: 'Nov', uv: 2490
  },
  {
    name: 'Dec', uv: 2490
  },
];
const options= {
  chart: {
    id: "basic-bar"
  },
  xaxis: {
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  },
  plotOptions: {
    bar: {
      borderRadius: 5,
      borderRadiusApplication: 'end',
      distributed: true,
    }
  },
  stroke: {
    width: 4,
  },
  dataLabels: {
    formatter: (val) => {
      return ''
    }
  },
}
const series = [
  {
    name: "users",
    data: ['30%', 40, 45, 50, 49, 60, 70, 91, 60, 67, 35, 77]
  }
]

const Home = () => {
  const [formData ,setFormData] = useState({})
  const [loading, setLoading] = useState(false)

  const [services, setServices] = useState([
    {label: 'Grocery Store', name: 'Governorate name'},
    {label: 'Grocery Store', name: 'Governorate name'},
    {label: 'Grocery Store', name: 'Governorate name'},
    {label: 'Grocery Store', name: 'Governorate name'},
    {label: 'Grocery Store', name: 'Governorate name'},
    {label: 'Grocery Store', name: 'Governorate name'},
  ])
  const adminService = new AdminService()
  const lang = useSelector(state=> state.auth.lang)

  useEffect(()=>{
    setLoading(true)
    adminService.getDashboard().then(res=>{
      if(res && res?.status===200){
        setFormData(res.data.data)
      }
      setLoading(false)
    })
  },[])

  if(loading) {
    return <Row>
      {Object.entries(formData)?.map(data=>{
        return <Col className="col-md-4 col-sm-6">
          <Card style={{height: '130px'}}>
            <Card.Body>
              <div class="skeleton-loader">
                  <div class="loader-header"></div>
                  <div class="loader-content"></div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      })}
    </Row>
  }

  return (
      <div className="dashboard">
        <div className="row">
          <div className="col-md-3 col-sm-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div style={{ textAlign: lang=== 'ar' ? 'right' : 'left'}}>
                    <p className="fs-14 mb-1">{Translate[lang]?.num_of_users}</p>
                    <span className="fs-24 text-black font-w700">
                      {formData?.totalUsers}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div style={{ textAlign: lang=== 'ar' ? 'right' : 'left'}}>
                    <p className="fs-14 mb-1">{Translate[lang]?.num_of_admins}</p>
                    <span className="fs-24 text-black font-w700">
                      {formData?.totalAdmins}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div style={{ textAlign: lang=== 'ar' ? 'right' : 'left'}}>
                    <p className="fs-14 mb-1">{Translate[lang]?.num_of_services}</p>
                    <span className="fs-24 text-black font-w700">
                    {formData?.totalServices}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div style={{ textAlign: lang=== 'ar' ? 'right' : 'left'}}>
                    <p className="fs-14 mb-1">{Translate[lang]?.num_of_death}</p>
                    <span className="fs-24 text-black font-w700">
                    {formData?.totalDeaths}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div style={{ textAlign: lang=== 'ar' ? 'right' : 'left'}}>
                    <p className="fs-14 mb-1">{Translate[lang]?.num_of_dawawen}</p>
                    <span className="fs-24 text-black font-w700">
                    {formData?.totalDawawen}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div style={{ textAlign: lang=== 'ar' ? 'right' : 'left'}}>
                    <p className="fs-14 mb-1">{Translate[lang]?.num_of_news}</p>
                    <span className="fs-24 text-black font-w700">
                    {formData?.totalNews}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div style={{ textAlign: lang=== 'ar' ? 'right' : 'left'}}>
                    <p className="fs-14 mb-1">{Translate[lang]?.num_of_ads}</p>
                    <span className="fs-24 text-black font-w700">
                    {formData?.totalAds}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div style={{ textAlign: lang=== 'ar' ? 'right' : 'left'}}>
                    <p className="fs-14 mb-1">{Translate[lang]?.num_of_offers}</p>
                    <span className="fs-24 text-black font-w700">
                    {formData?.totalOffers}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div style={{ textAlign: lang=== 'ar' ? 'right' : 'left'}}>
                    <p className="fs-14 mb-1">{Translate[lang]?.num_of_events}</p>
                    <span className="fs-24 text-black font-w700">
                    {formData?.totalEvents}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <Row>
          <Col lg={6} md={12}>
              <Card>
                <CardBody>
                  <h5 className="mt-1">{Translate[lang].govenrate}</h5>
                  <Row className="mt-4">
                    <Col sm={6} className="mb-3">
                      <div className="div-border">
                        <h6 className="m-0" style={{fontSize: '16px'}}>{Translate[lang].hawally}</h6>
                        <p className="mb-0" style={{fontSize: '12px'}}>{formData.totalUsersInHawally} {Translate[lang].users}</p>
                      </div>
                    </Col>
                    <Col sm={6} className="mb-3">
                      <div className="div-border">
                        <h6 className="m-0" style={{fontSize: '16px'}}>{Translate[lang].kuwait}</h6>
                        <p className="mb-0" style={{fontSize: '12px'}}>{formData.totalUsersInKuwait} {Translate[lang].users}</p>
                      </div>
                    </Col>
                    <Col sm={6} className="mb-3">
                      <div className="div-border">
                        <h6 className="m-0" style={{fontSize: '16px'}}>{Translate[lang].mubarak_kabeer}</h6>
                        <p className="mb-0" style={{fontSize: '12px'}}>{formData.totalUsersInMubarakKabeer} {Translate[lang].users}</p>
                      </div>
                    </Col>
                    <Col sm={6} className="mb-3">
                      <div className="div-border">
                        <h6 className="m-0" style={{fontSize: '16px'}}>{Translate[lang].ahmadi}</h6>
                        <p className="mb-0" style={{fontSize: '12px'}}>{formData.totalUsersInAhmadi} {Translate[lang].users}</p>
                      </div>
                    </Col>
                    <Col sm={6} className="mb-3">
                      <div className="div-border">
                        <h6 className="m-0" style={{fontSize: '16px'}}>{Translate[lang].farwaniya}</h6>
                        <p className="mb-0" style={{fontSize: '12px'}}>{formData.totalUsersInFarwaniya} {Translate[lang].users}</p>
                      </div>
                    </Col>
                    <Col sm={6} className="mb-3">
                      <div className="div-border">
                        <h6 className="m-0" style={{fontSize: '16px'}}>{Translate[lang].jahraa}</h6>
                        <p className="mb-0" style={{fontSize: '12px'}}>{formData.totalUsersInJahraa} {Translate[lang].users}</p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
          </Col>

          {/* <Col md={6}>
            <Card>
                <CardBody>
                  <h5 className="mt-1">{Translate[lang].recent_serviceses_added}</h5>
                  <Row className="mt-4 services">
                    {services?.map((service, index)=>{
                      return <Col sm={6} key={index} className='d-flex mb-3'>
                        <div className="image">
                          <img src={basket} alt={service.label} />
                        </div>
                        <div >
                          <h6 className="m-0" style={{fontSize: '16px'}}>{service.label}</h6>
                          <p  style={{fontSize: '12px', margin: '0'}}>{service.name}</p>
                        </div>
                      </Col>
                    })}
                  </Row>
                </CardBody>
            </Card>
          </Col> */}
        </Row>
        {/* <Card>
              <CardBody>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={900}
                    height={500}
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="uv" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>

                <label>{Translate[lang].precentage_of_users}</label>
                <Chart
                  options={options}
                  series={series}
                  type="bar"
                  // width="500"
                  height='350'
                />
              </CardBody>
            </Card> */}
      </div>  
  );
};

export default Home;
