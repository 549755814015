import React, { useEffect, useReducer, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
/// Link
import { Link } from "react-router-dom";
import logo from "../../../images/logo-small.svg";
import { MenuList } from "../../Enums/Menu";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { Translate } from "../../Enums/Tranlate";
import Logout from "../../pages/Authintication/Logout";
import GovernorateService from "../../../services/GovernorateService";
import { useDispatch, useSelector } from "react-redux";
import { setGovernate, setPath, setSearch } from "../../../store/actions/AuthActions";

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
};

const SideBar = (props) => {
  const [governorates, setGovernorates] = useState([]);
  const [state, setState] = useReducer(reducer, initialState);
  const lang = useSelector((state) => state.auth?.lang);
  const governorateService = new GovernorateService();
  const dispatch = useDispatch();

  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  const handleMenuActive = (status) => {
    setState({ active: status });
    if (
      state.active === status &&
      MenuList.filter((res) => res?.title === status)[0]?.hasOwnProperty(
        "content"
      )
    ) {
      setState({ active: "" });
    }
  };

  useEffect(() => {
    if (governorates?.length === 0) {
      governorateService.getList().then((res) => {
        if (res.status === 200) {
          let data = res.data.data?.map((item) => {
            return {
              ...item,
              to: item.name_en?.toLocaleLowerCase().replaceAll(" ", "_"),
              text: item.name_en?.toLocaleLowerCase().replaceAll(" ", "_"),
              title: item.name_en,
            };
          });
          setGovernorates(data);
          dispatch(setGovernate(data))
        }
      });
    }
  }, []);

  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }
  };

  useEffect(() => {
    let page = window.location.pathname;
    let filter = MenuList?.filter((res) => `${res.to}` === page?.split("/")[1]);

    if (filter.length > 0) handleMenuActive(filter[0].title);
  }, [window.location.pathname]);

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  return (
    <div className="deznav ">
      <div className="deznav-scroll">
        <img
          src={logo}
          alt="logo"
          className="logo"
          style={{
            borderRadius: "0",
            marginTop: "47px",
            marginBottom: "15px",
            marginRight: lang === "ar" ? "38px" : "auto",
            marginLeft: lang === "en" ? "38px" : "auto",
          }}
        />
        <ul
          className="metismenu"
          style={{ minHeight: "70vh", marginBottom: "1rem" }}
          id="menu"
        >
          {MenuList.map((data, index) => {
            let menuClass = data.classsChange;
            if (menuClass === "menu-title") {
              return (
                <li 
                  className={menuClass} 
                  key={index}
                  onClick={() =>{
                    dispatch(setPath(data.title))
                    dispatch(setSearch(""))
                  }}
                >
                  {data.title}
                </li>
              );
            } else {
              return (
                <li
                  className={` ${
                    state.active === data.title ? "mm-active" : ""
                  }`}
                  key={index}
                >
                  {/* {data.content && data.content.length > 0 ? */}
                  {data.text === "governorate" ? (
                    <>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        className="has-arrow"
                        onClick={() => {
                          handleMenuActive(data.title);
                        }}
                      >
                        {state.active === data.title
                          ? data.whiteImage
                          : data.blackImage}
                        <span className="nav-text">
                          {Translate[lang][data.text]}
                        </span>
                      </a>
                      <Collapse in={state.active === data.title ? true : false}>
                        <ul
                          className={`${
                            menuClass === "mm-collapse" ? "mm-show" : ""
                          }`}
                        >
                          {governorates &&
                            governorates?.map((gov, index) => {
                              return (
                                <li
                                  key={index}
                                  className={`${
                                    state.activeSubmenu === gov.title
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() => handleSubmenuActive(gov.title)}
                                >
                                  <Link
                                    to={`${gov.to}`}
                                    state={gov}
                                    onClick={() =>{
                                      dispatch(setPath("services"))
                                      dispatch(setSearch(""))
                                    }}
                                  >
                                    {Translate[lang].governorate}{" "}
                                    {lang === "en" ? gov.name_en : gov.name_ar}
                                  </Link>
                                </li>
                              );
                            })}
                        </ul>
                      </Collapse>
                    </>
                  ) : (
                    <Link
                      to={data.to}
                      onClick={() => handleMenuActive(data.title)}
                    >
                      {state.active === data.title
                        ? data.whiteImage
                        : data.blackImage}
                      <span className="nav-text">
                        {Translate[lang][data.text]}
                      </span>
                    </Link>
                  )}
                </li>
              );
            }
          })}
        </ul>
      </div>
      <div className="text-center mb-3" style={{ padding: " 8px 22px" }}>
        <Logout />
        {/* <button style={{
            padding: '8px 12px',
            background: 'none',
            border: 'none',
            color: 'var(--danger)',
            width: '100%',
            textAlign: lang === 'ar' ? 'right' :'left'
          }}>
            <img src={logout} alt='logout' style={{
              marginLeft: lang === 'ar' ? '12px' : 'auto',
              marginRight: lang === 'en' ? '12px' : 'auto',
            }} />
            
        </button> */}
      </div>
    </div>
  );
};

export default SideBar;
