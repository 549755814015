import { AvField, AvForm } from "availity-reactstrap-validation";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import location from "../../../images/icons/location.svg";
import uploadImg from "../../../images/upload-img.png";
import { Translate } from "../../Enums/Tranlate";
import Select from "react-select";
import BaseService from "../../../services/BaseService";
import { useLocation, useNavigate } from "react-router-dom";
import AreasService from "../../../services/AreasServices";
import Service from "../../../services/Services";
import { setPath } from "../../../store/actions/AuthActions";
import { toast } from "react-toastify";

const AddForm = () => {
  const [formData, setFormData] = useState({
    name_ar: "",
    name_en: "",
    location_url: "",
    location_manual: "",
    phone: "",
    description_en: "",
    description_ar: "",
    comments: true,
    reacts: false,
    share: false,
    date: "",
    images: [],
  });
  let location = useLocation();
  const [loading, setLoading] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);
  const lang = useSelector((state) => state.auth?.lang);
  const filter = useSelector((state) => state.auth);
  const areasService = new AreasService();
  const service = new Service();
  const [area, setArea] = useState("");
  const [areasOptions, setAreasOptions] = useState([]);
  let path = filter.path || localStorage.getItem("path");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    areasService?.getList(location.state?.id).then((res) => {
      if (res.status === 200) {
        let data = res.data.data?.map((item) => {
          return {
            ...item,
            label: lang === "en" ? item.name_en : item.name_ar,
            value: item.id,
          };
        });
        setAreasOptions(data);
      }
    });
  }, [location]);

  useEffect(() => {
    if (!!location?.state?.edit_id) {
      setLoading(true);
      service.find(location?.state?.edit_id).then((res) => {
        if (res?.status === 200) {
          setFormData({
            ...res.data?.data,
            images: res.data?.data?.images?.map((img) => img.url),
            date: res.data?.data?.date?.split("T")[0],
          });
          setArea({
            label:
              lang === "en"
                ? res.data?.data?.area?.name_en
                : res.data?.data?.area?.name_ar,
            value: res.data?.data?.area?.id,
          });
        }
        setLoading(false);
      });
    }
  }, [location?.state]);

  const inputHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const fileHandler = (e) => {
    setLoadingImg(true);
    // let imgs = []
    let files = e.target.files;
    const filesData = Object.values(files);
    if (filesData?.length) {
      filesData?.map((file, index) => {
        new BaseService().postUpload(file).then((res) => {
          if (res.data?.status === 200) {
            setFormData({
              ...formData,
              images: [...formData?.images, res.data.url],
            });
            setLoadingImg(false);
          }
        });
      });
    }
  };

  const onSubmit = () => {
    if (!area?.value) {
      toast.error(`Select Area First`);
      return;
    }
    if (formData?.images?.length === 0) {
      toast.error(`Upload Images First`);
      return;
    }
    if (!formData?.description_ar) {
      toast.error(`Description Arabic not allowed to be empty`);
      return;
    }
    setLoading(true);
    let data = {
      name_ar: formData.name_ar,
      name_en: formData.name_en,
      area_id: area?.value,
      governorate_id: location?.state?.id,
      type: location.state?.type === 'services' ? location.state?.tab : location.state?.type,
      images: formData.images,
      description_ar: formData.description_ar,
      description_en: formData.description_en,
      share: formData.share,
    };
    if (!!formData?.location_manual)
      data["location_manual"] = formData?.location_manual;
    if (path === "news" || path === "offers") data["date"] = formData.date;
    if (path === "events" || path === "services") {
      data["location_url"] = formData.location_url;
    }
    if (path === "services") {
      data["comments"] = formData.comments;
      data["reacts"] = formData.reacts;
      data["phone"] = formData.phone;
      data["country_code"] = "+965"; //default Code for Kuwait
    }
    if (!!location?.state?.edit_id) {
      service.update(location?.state?.edit_id, data).then((res) => {
        if (res?.status === 200) {
          toast.success(
            `${Translate[lang].updated} ${
              Translate[lang][location.state.type]
            } ${Translate[lang].successfully}`
          );
          if(location?.state?.from==="details"){
            navigate(
              `/${location.state?.to}/${location.state.type}/${location?.state?.edit_id}`,
              { state: { ...location.state } }
            );
          } else {
            navigate(
              `/${location.state?.to}`,
              { state: { ...location.state } }
            );
          }
          
          dispatch(setPath(location.state.type));
        }
        setLoading(false);
      });
    } else {
      service.create(data).then((res) => {
        if (res?.status === 201) {
          toast.success(
            `${Translate[lang].added} ${Translate[lang][location.state.type]} ${
              Translate[lang].successfully
            }`
          );
          navigate(`/${location.state?.to}`, { state: { ...location.state } });
          dispatch(setPath(location.state.type));
        }
        setLoading(false);
      });
    }
  };

  const deleteImg = (index) => {
    let update = formData?.images?.filter((_, ind) => ind !== index);
    setFormData({ ...formData, images: update });
  };

  return (
    <Card>
      <CardBody>
        <h4
          className="text-primary py-3 w-75-responsive"
          style={{ borderBottom: "1px solid #dedede" }}
        >
          {Translate[lang].add} {Translate[lang][location.state.type]}
        </h4>
        <AvForm onValidSubmit={onSubmit} className="pt-4 w-75-responsive">
          <div className="row">
            <div className="col-lg-6 col-sm-6 mb-3">
              <AvField
                name="name_en"
                type="text"
                style={{
                  width: "100%",
                  fontSize: "18px",
                  fontWeight: "700",
                  lineHeight: "27.5px",
                  border: "none",
                  borderBottom: "1px solid #dedede",
                  paddingBottom: "10px",
                }}
                value={formData?.name_en}
                validate={{
                  required: {
                    value: true,
                    errorMessage: Translate[lang].field_required,
                  },
                }}
                placeholder={`${Translate[lang]?.write_services_name} ${Translate[lang]?.by_english}...`}
                onChange={(e) => inputHandler(e)}
              />
            </div>
            <div className="col-lg-6 col-sm-6 mb-3">
              <AvField
                name="name_ar"
                type="text"
                style={{
                  width: "100%",
                  fontSize: "18px",
                  fontWeight: "700",
                  lineHeight: "27.5px",
                  border: "none",
                  borderBottom: "1px solid #dedede",
                  paddingBottom: "10px",
                }}
                value={formData?.name_ar}
                validate={{
                  required: {
                    value: true,
                    errorMessage: Translate[lang].field_required,
                  },
                }}
                placeholder={`${Translate[lang]?.write_services_name} ${Translate[lang]?.by_arabic}...`}
                onChange={(e) => inputHandler(e)}
              />
            </div>
            <div className="col-lg-6 col-sm-6  my-3">
              <Select
                value={area}
                name="area"
                className="w-100"
                placeholder={Translate[lang]?.area}
                options={areasOptions}
                onChange={(e) => setArea(e)}
              />
              {/* <div className="position-relative w-100 mx-4">
                    <img src={location} alt='location' className="location-area"/>
                    
                </div> */}
            </div>
            <div className="w-100 mb-3" style={{ padding: "0 15px" }}>
              <p
                className="mb-0"
                style={{
                  fontSize: "14px",
                  color: "#1A1A1A",
                  fontWeight: "700",
                }}
              >
                {Translate[lang]?.service_image}
                <span style={{ color: "#ED4F9D" }}>*</span>
              </p>
              <span
                style={{
                  fontSize: "12px",
                  color: "#939393",
                  fontWeight: "400",
                }}
              >
                Image format .jpg .jpeg .png and minimum size 300 x 300px
              </span>
              <div className="row my-3">
                <div className="col-md-2 image-placeholder">
                  <div className="avatar-edit d-flex justify-content-center">
                    <input
                      type="file"
                      onChange={(e) => fileHandler(e)}
                      id={`imageUpload`}
                    />
                    <label htmlFor={`imageUpload`}>
                      <img
                        src={uploadImg}
                        alt="icon"
                        style={{
                          width: "40px",
                          height: "40px",
                          marginTop: "10px",
                        }}
                      />
                      <span
                        style={{ display: "inline-block", fontSize: "14px" }}
                      >
                        {Translate[lang].new_image}
                      </span>
                    </label>
                  </div>
                </div>
                {formData?.images.map((img, index) => {
                  return (
                    <div className="col-md-2 image-placeholder">
                      <button
                        className="delete-img"
                        type="button"
                        style={{
                          position: "absolute",
                          zIndex: "1",
                          color: "red",
                          background: "#fdfdfd",
                          borderRadius: "50%",
                          fontSize: "18px",
                          cursor: "pointer",
                          right: "20px",
                          top: "3px",
                          border: "none",
                          padding: "1px 5px",
                        }}
                        onClick={() => deleteImg(index)}
                      >
                        <i className="la la-trash"></i>
                      </button>
                      <div className="avatar-preview">
                        <div id={`imagePreview${index}`}>
                          <img
                            id={`saveImageFile${index}`}
                            src={img}
                            alt="icon"
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {path === "services" && (
              <>
                <div className="col-lg-6 col-sm-6 col-sm-12 mb-3 position-relative">
                  <i
                    className="la la-map-marker location-area"
                    style={{
                      left: "22px",
                      top: "40px",
                      fontSize: "22px",
                    }}
                  ></i>
                  <AvField
                    label={`${Translate[lang].location} (${Translate[lang].url})`}
                    type="text"
                    placeholder={`${Translate[lang].location}`}
                    bsSize="lg"
                    name="location_url"
                    style={{
                      paddingLeft: "38px",
                    }}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: Translate[lang].field_required,
                      },
                    }}
                    value={formData.location_url}
                    onChange={(e) =>
                      setFormData({ ...formData, location_url: e.target.value })
                    }
                  />
                </div>
                <div className="col-lg-6 col-sm-6 col-sm-12 mb-3 position-relative">
                  <i
                    className="la la-map-marker location-area"
                    style={{
                      left: "22px",
                      top: "40px",
                      fontSize: "22px",
                    }}
                  ></i>
                  <AvField
                    label={`${Translate[lang].location} (${Translate[lang].manual})`}
                    type="text"
                    placeholder={`${Translate[lang].location}`}
                    bsSize="lg"
                    name="location_manual"
                    style={{
                      paddingLeft: "38px",
                    }}
                    // validate={{
                    //     required: {
                    //         value: true,
                    //         errorMessage: Translate[lang].field_required
                    //     }
                    // }}
                    value={formData.location_manual}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        location_manual: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-lg-6 col-sm-6 col-sm-12 mb-3 position-relative">
                  <i
                    className="la la-phone location-area"
                    style={{
                      left: "22px",
                      top: "40px",
                      fontSize: "22px",
                    }}
                  ></i>
                  <AvField
                    label={`${Translate[lang].phone}`}
                    type="text"
                    placeholder={`${Translate[lang].phone}`}
                    bsSize="lg"
                    name="phone"
                    style={{
                      paddingLeft: "38px",
                    }}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: Translate[lang].field_required,
                      },
                    }}
                    value={formData.phone}
                    onChange={(e) =>
                      setFormData({ ...formData, phone: e.target.value })
                    }
                  />
                </div>
              </>
            )}
            {path === "events" && (
              <div className="col-lg-6 col-sm-6 col-sm-12 mb-3 position-relative">
                <i
                  className="la la-map-marker location-area"
                  style={{
                    left: "22px",
                    top: "40px",
                    fontSize: "22px",
                  }}
                ></i>
                <AvField
                  label={`${Translate[lang].location}`}
                  type="text"
                  placeholder={`${Translate[lang].location}`}
                  bsSize="lg"
                  name="location_url"
                  style={{
                    paddingLeft: "38px",
                  }}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: Translate[lang].field_required,
                    },
                  }}
                  value={formData.location_url}
                  onChange={(e) =>
                    setFormData({ ...formData, location_url: e.target.value })
                  }
                />
              </div>
            )}

            {(path === "news" || path === "offers") && (
              <div className="col-lg-6 col-sm-6 mb-3 position-relative">
                <i
                  className="la la-calendar location-area"
                  style={{
                    left: "22px",
                    top: "40px",
                    fontSize: "22px",
                  }}
                ></i>
                <AvField
                  label={`${Translate[lang].date}`}
                  type="date"
                  placeholder={`${Translate[lang].date}`}
                  bsSize="lg"
                  name="date"
                  style={{
                    paddingLeft: "38px",
                  }}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: Translate[lang].field_required,
                    },
                  }}
                  value={formData.date}
                  onChange={(e) =>
                    setFormData({ ...formData, date: e.target.value })
                  }
                />
              </div>
            )}

            <div className="col-lg-12 col-sm-12 mb-3">
              <label className="d-block">
                {Translate[lang]?.description} {Translate[lang]?.by_english}
              </label>
              <textarea
                style={{
                  border: "1px solid hsl(0, 0%, 80%)",
                  padding: "6px 12px",
                  width: "100%",
                  borderRadius: "0.3rem",
                  maxHeight: "95px",
                  minHeight: "90px",
                  height: "95px",
                }}
                name="description"
                type="text"
                value={formData.description_en}
                required
                placeholder={`${Translate[lang].add} ${Translate[lang].description} ${Translate[lang]?.by_english}`}
                onChange={(e) =>
                  setFormData({ ...formData, description_en: e.target.value })
                }
              />
            </div>
            <div className="col-lg-12 col-sm-12 mb-3">
              <label className="d-block">
                {Translate[lang]?.description} {Translate[lang]?.by_arabic}
              </label>
              <textarea
                style={{
                  border: "1px solid hsl(0, 0%, 80%)",
                  padding: "6px 12px",
                  width: "100%",
                  borderRadius: "0.3rem",
                  maxHeight: "95px",
                  minHeight: "90px",
                  height: "95px",
                }}
                name="description"
                type="text"
                value={formData.description_ar}
                required
                placeholder={`${Translate[lang].add} ${Translate[lang].description} ${Translate[lang]?.by_arabic}`}
                onChange={(e) =>
                  setFormData({ ...formData, description_ar: e.target.value })
                }
              />
            </div>
            <div className="col-lg-12 col-sm-12 mb-3">
              <label>{Translate[lang].add_reacts_for_user}</label>
              {path === "services" && (
                <div className="mb-2">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="comments"
                    onChange={(e) =>
                      setFormData({ ...formData, comments: e.target.checked })
                    }
                    label={Translate[lang].comments}
                    value={formData.comments}
                    checked={formData.comments}
                  />
                </div>
              )}
              {path === "services" && (
                <div className="mb-2">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="reacts"
                    onChange={(e) =>
                      setFormData({ ...formData, reacts: e.target.checked })
                    }
                    label={Translate[lang].reacts}
                    value={formData.reacts}
                    checked={formData.reacts}
                  />
                </div>
              )}
              <div>
                <Form.Check // prettier-ignore
                  type="switch"
                  id="share"
                  onChange={(e) =>
                    setFormData({ ...formData, share: e.target.checked })
                  }
                  label={Translate[lang].share}
                  value={formData.share}
                  checked={formData.share}
                />
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between mt-3">
            <div></div>
            <Button variant="primary" type="submit" disabled={loading}>
              {!!location?.state?.edit_id
                ? Translate[lang]?.edit
                : Translate[lang]?.add}
            </Button>
          </div>
        </AvForm>
      </CardBody>
    </Card>
  );
};
export default AddForm;
