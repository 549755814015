import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import cover from "../../../../images/cover.svg";
import defaultProfile from "../../../../images/default-profile.svg";
import Service from "../../../../services/Services";
import { setPath } from "../../../../store/actions/AuthActions";
import DeleteModal from "../../../common/DeleteModal";
import Loader from "../../../common/Loader";
import { Translate } from "../../../Enums/Tranlate";
import "../style.scss";

const Details = (props) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const lang = useSelector((state) => state.auth?.lang);
  const service = new Service();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    let id = window.location.pathname?.split("/")[3];
    setLoading(true);
    service.find(id).then((res) => {
      if (res?.status === 200) {
        setData(res.data.data);
      }
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <div style={{ height: "300px" }}>
        <Loader />
      </div>
    );
  }
  return (
    <div className="details">
      <Card>
        <div className="cover">
          <img src={cover} alt="cover" className="w-100" />
          {/* <i className="la la-edit"></i> */}
        </div>
        <div className="header-div d-flex">
          <div className="profile-img" style={{
            border: '2px solid #fff',
            borderRadius: '8px'
          }}>
            <img
              src={
                data?.images?.length > 0 ? data?.images[0].url : defaultProfile
              }
              alt="profile"
              className="w-100 h-100"
              style={{
                borderRadius: '6px'
              }}
            />
          </div>
          <div className="profile-details d-flex mt-3">
            <div>
              <h4 className="title  m-0">
                {lang === "en" ? data?.name_en : data?.name_ar}
                {/* <i className="la la-edit"></i> */}
              </h4>
              <p className="location m-0">
                <i className="la la-map-marker"></i>
                {lang === "en" ? data?.area?.name_en : data?.area?.name_ar}
                {/* <i className="la la-edit"></i> */}
              </p>
              {location?.state?.type === "services" && (
                <p className="phone m-0">
                  <i className="la la-phone"></i>
                  <span style={{ direction: "ltr" }}>{data.phone}</span>
                  {/* <i className="la la-edit"></i> */}
                </p>
              )}
              {(location?.state?.type === "news" ||
                location?.state?.type === "offers") && (
                <p className="phone m-0">
                  <i className="la la-calendar"></i>
                  <span>{data.date?.split("T")[0]}</span>
                  {/* <i className="la la-edit"></i> */}
                </p>
              )}
            </div>
            {/* <div className="d-flex align-items-end" style={{gap: '30px'}}>
                        <p style={{color:'#656575', margin: '5px'}}>
                            {Translate[lang].share}: 2200
                        </p>
                        <p style={{color:'#656575', margin: '5px'}}>
                            {Translate[lang].likes}: 2200
                        </p>
                        <p style={{color:'#656575', margin: '5px'}}>
                            {Translate[lang].comments}: 3200
                        </p>
                    </div> */}
            <div>
              <i
                className="la la-edit ml-2 mt-4"
                style={{ fontSize: "20px" }}
                onClick={() => {
                  navigate(`/${location?.state?.to}/add`, {
                    state: { ...location?.state,from: 'details', edit_id: data.id },
                  });
                  dispatch(setPath(location?.state?.type));
                }}
              ></i>
              <i
                className="la la-trash text-danger ml-2 mt-4"
                style={{ fontSize: "20px" }}
                onClick={() => setDeleteModal(true)}
              ></i>
            </div>
          </div>
        </div>
      </Card>

      <h5 className="mb-2">{Translate[lang].added_photo}</h5>
      <Row>
        {data?.images?.map((img, index) => {
          return (
            <Col md={2} sm={4} key={index} className='mt-1'>
              <a href={img?.url} target='_blank' rel="noreferrer">
              <img
                src={img?.url}
                alt={index}
                className="w-100"
                style={{ height: "110px" }}
              />
              </a>
            </Col>
          );
        })}
      </Row>

      <h5 className="mt-4 mb-2">{Translate[lang].description}</h5>
      <p className="text-black">
        {lang === "en" ? data?.description_en : data?.description_ar}
      </p>

        {data?.service_comments?.length > 0 && <>
          <h5 className="mt-4 mb-3">{Translate[lang].comments}</h5>
          {data?.service_comments?.map((comment, index) => {
            return <div key={comment.id} className='mb-3 d-flex'>
              <div style={{
                padding: '0.3rem 1rem',
                background: '#fff',
                borderRadius: '8px',
                fontSize: '2rem',
                width: '60px',
                height: '60px',
                textAlign: 'center'
              }}>
                <i className="fa fa-user"></i>
              </div>
              <div className="mx-2">
                <span>{Translate[lang].user}</span>
                <p className="mb-0 text-black mt-1">{comment?.comment}</p>
              </div>
            </div>
          })}
        </>}
      <h5 className="mt-4">{Translate[lang].reacts_for_user}</h5>
      <div className="d-flex" style={{ gap: "16px" }}>
        {location?.state?.type === "services" && (
          <Form.Check
            type="switch"
            id="comments"
            label={Translate[lang].comments}
            checked={data.comments}
          />
        )}
        {location?.state?.type === "services" && (
          <Form.Check
            type="switch"
            id="reacts"
            label={Translate[lang].reacts}
            checked={data.reacts}
          />
        )}
        <Form.Check
          type="switch"
          id="share"
          label={Translate[lang].share}
          checked={data.share}
        />
      </div>
      {deleteModal && (
            <DeleteModal
              open={deleteModal}
              titleMsg={lang === "en" ? data.name_en : data.name_ar}
              deletedItem={data}
              modelService={service}
              onCloseModal={setDeleteModal}
            />
          )}
    </div>
  );
};
export default Details;
