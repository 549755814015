import { useEffect, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdminService from "../../../services/AdminService";
import Loader from "../../common/Loader";
import NoData from "../../common/NoData";
import Pagination from "../../common/Pagination/Pagination";
import { Translate } from "../../Enums/Tranlate";
import CardItem from "./CardItem";
import "./style.scss";
import Select from "react-select";
import { Rules } from "../../Enums/Rules";
import { toast } from "react-toastify";
import { changeAdminRules } from "../../../store/actions/AuthActions";

const Admins = () => {
  const [admins, setAdmins] = useState(null);
  const [selectedAdmin, setSelectedAdmin] = useState("");
  const [adminsOptions, setAdminsOptions] = useState([]);
  const [hasData, setHasData] = useState(1);
  const [type, setType] = useState("admins");
  const [formData, setFormData] = useState([]);
  const [search, setSearch] = useState(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminService = new AdminService();
  const Auth = useSelector((state) => state.auth?.auth);
  const isExist = (data) => Auth?.admin?.admin_roles?.includes(data);
  const lang = useSelector((state) => state.auth?.lang);

  useEffect(() => {
    if (selectedAdmin?.length > 1) {
      setFormData([]);
    } else if (selectedAdmin?.length === 1) {
      setFormData(
        selectedAdmin[0]?.admin?.admin_roles?.map((res) => res?.role)
      );
    }
  }, [selectedAdmin]);

  useEffect(()=>{
    if(type !== 'admins'){
      adminService?.getList()?.then(res=>{
        if(res.status === 200){
          let data = res?.data?.data?.data?.map((res2) => {
            return {
              label: `${res2?.f_name} ${res2?.l_name}`,
              value: res2?.id,
              admin: res2,
            };
          })
          setAdminsOptions(data)
        }
      })
    }
  } ,[type])

  const addRules = (e) => {
    e.preventDefault();
    if (selectedAdmin?.length === 0) {
      toast.error("Select Admin First");
      return;
    }
    setLoading(true);
    selectedAdmin?.map((admin, index) => {
      let data = {
        f_name: admin?.admin?.f_name,
        l_name: admin?.admin?.l_name,
        country_code: admin?.admin?.country_code,
        phone: admin?.admin?.phone,
        rules: formData,
      };
      adminService.update(admin?.admin?.id, data).then((response) => {
        if (response?.status === 200 && index === selectedAdmin?.length - 1) {
          toast.success(
            `${Translate[lang]?.admins} ${Translate[lang]?.updated} ${Translate[lang]?.successfully}`
          );
          dispatch(changeAdminRules(formData))
        }
        setLoading(false);
      });
    });
  };

  return (
    <div className="admins">
      <Card style={{ borderRadius: "8px" }}>
        <div className="d-flex px-4 py-2 justify-content-between mt-3 align-items-center">
          <div></div>
          {
            <Button
              variant="primary"
              className="me-2 h-75"
              onClick={() => navigate("/admins/add-admins")}
            >
              + {Translate[lang]?.add_admin}
            </Button>
          }
        </div>
        <div className="d-flex mt-3 align-items-center text-center tabs">
          <p
            className={`${type === "admins" ? "active" : ""}`}
            onClick={() => setType("admins")}
          >
            {Translate[lang].admins}
          </p>
          <p
            className={`${type !== "admins" ? "active" : ""}`}
            onClick={() => {
              setType("rules")
              setSelectedAdmin('')
              setFormData([])
            }}
          >
            {Translate[lang].rules}
          </p>
        </div>
      </Card>

      <Card>
        {type === "admins" ? (
          <Card.Body className={`${hasData === 0 ? "text-center" : ""}`}>
            {loading && (
              <div style={{ height: "300px" }}>
                <Loader />
              </div>
            )}
            {hasData === 1 && !loading && (
              <Table responsive>
                <thead>
                  <tr>
                    <th className="px-2">{Translate[lang]?.name}</th>
                    <th className="px-2">{Translate[lang]?.email}</th>
                    <th className="px-2">{Translate[lang]?.phone}</th>
                    <th className="px-2">{Translate[lang]?.permissions}</th>
                    <th className="px-2">{Translate[lang]?.status}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {admins?.length > 0 &&
                    admins?.map((item, index) => {
                      return (
                        <CardItem
                          key={index}
                          index={index}
                          item={item}
                          setShouldUpdate={setShouldUpdate}
                        />
                      );
                    })}
                </tbody>
              </Table>
            )}
            {hasData === 0 && <NoData />}
            <Pagination
              setData={setAdmins}
              service={adminService}
              shouldUpdate={shouldUpdate}
              setHasData={setHasData}
              setLoading={setLoading}
              search={search}
            />
          </Card.Body>
        ) : (
          <Card.Body>
            <form onSubmit={addRules}>
              <div className="d-flex px-4 py-2 justify-content-between ">
                <div></div>
                <div className="w-50">
                  <Select
                    value={selectedAdmin}
                    name="country_code"
                    placeholder={Translate[lang]?.select}
                    options={adminsOptions}
                    isMulti
                    onChange={(e) => setSelectedAdmin(e)}
                  />
                </div>
              </div>
              <Table responsive className="mt-4">
                <thead>
                  <tr>
                    <th className="w-50">{Translate[lang]?.rule}</th>
                    <th className="w-25 text-center">
                      {Translate[lang]?.full_permissions}
                    </th>
                    <th className="w-25 text-center">
                      {Translate[lang]?.read_only}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Rules?.map((rul, index) => {
                    return (
                      <tr key={index}>
                        <th style={{ fontWeight: "500" }}>
                          {Translate[lang][rul.value]}
                        </th>
                        <th className="text-center">
                          <input
                            type="radio"
                            style={{
                              width: "20px",
                              height: "20px",
                              accentColor: "var(--primary)",
                            }}
                            name={rul.value}
                            checked={formData?.includes(rul?.value)}
                            onChange={() =>
                              setFormData([...formData, rul.value])
                            }
                          />
                        </th>
                        <th className="text-center">
                          <input
                            type="radio"
                            style={{
                              width: "20px",
                              height: "20px",
                              accentColor: "var(--primary)",
                            }}
                            // checked={selectedAdmin?.length > 0 && !formData?.includes(rul.value)}
                            name={rul.value}
                            onChange={() => {
                              let update = formData?.filter(
                                (res) => res !== rul.value
                              );
                              setFormData([...update]);
                            }}
                          />
                        </th>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {isExist('admin') &&<div className="d-flex justify-content-end mt-4">
                  <Button variant="primary" type="submit" disabled={loading}>
                    {Translate[lang]?.submit}
                  </Button>
                </div>
              }
            </form>
          </Card.Body>
        )}
      </Card>
    </div>
  );
};
export default Admins;
