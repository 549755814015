import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AdminService from "../../../../services/AdminService";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import CountryiesService from "../../../../services/CountriesService";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { useSelector } from "react-redux";
import { Translate } from "../../../Enums/Tranlate";
import { Rules } from "../../../Enums/Rules";
const countryCodes = require("country-codes-list");

const AddAdmin = () => {
  const location = useLocation();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    country_code: "",
    password: "",
    rules: [],
    old_password: '',
    new_password: ''
  });
  const [loading, setLoading] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const adminService = new AdminService();
  // const countryiesService = new CountryiesService()
  const lang = useSelector((state) => state.auth?.lang);

  useEffect(() => {
    const myCountryCodesObject = countryCodes.customList(
      "countryCode",
      "{countryNameEn}/{countryNameLocal} +{countryCallingCode}"
    );
    const entries = Object.entries(myCountryCodesObject);
    setCountriesOptions(
      entries?.map((c) => {
        return {
          label: c[1].split("+")[0],
          value: c[1].split("+")[1],
        };
      })
    );
  }, []);

  useEffect(() => {
    if (location?.state?.edit) {
      let item = location.state?.item;
      setFormData({
        ...formData,
        first_name: item?.f_name,
        last_name: item?.l_name,
        email: item?.email,
        phone: item?.phone,
        country_code: countriesOptions?.filter(
          (res) => `+${res.value}` === location.state?.item?.country_code
        )[0],
        rules: item.admin_roles?.map((res) => res?.role),
      });
    }
  }, [countriesOptions]);

  const inputHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!formData.country_code) {
      toast.error("Select Country");
      return;
    }
    let data = {
      f_name: formData?.first_name,
      l_name: formData?.last_name,
      country_code: `+${formData?.country_code?.value}`,
      phone: formData?.phone,
      rules: formData.rules,
    };
    setLoading(true);
    if (location?.state?.edit) {
      adminService.update(location.state?.id, data).then((response) => {
        if (response?.status === 200) {
          toast.success(
            `${Translate[lang]?.admin} ${Translate[lang]?.updated} ${Translate[lang]?.successfully}`
          );
          navigate("/admins");
        }
        setLoading(false);
      });
    } else {
      data["password"] = formData?.password;
      data["email"] = formData?.email;

      adminService.create(data).then((response) => {
        if (response?.status === 201) {
          toast.success(
            `${Translate[lang]?.admin} ${Translate[lang]?.added} ${Translate[lang]?.successfully}`
          );
          navigate("/admins");
        }
        setLoading(false);
      });
    }
  };
  const handlerChangePassword = (e) => {
    e.preventDefault();

    let data = {
      old_password: formData?.old_password,
      new_password: formData?.new_password,
    };

    setLoading(true);
    adminService.changePassword(data).then((response) => {
      if (response?.status === 200) {
        toast.success(
          `${Translate[lang]?.admin} ${Translate[lang]?.updated} ${Translate[lang]?.successfully}`
        );
        navigate("/admins");
      }
      setLoading(false);
    });
  };

  return (
    <Card className="p-4">
      {!changePassword ? <AvForm onValidSubmit={onSubmit}>
        <div className="row">
          <div className="col-lg-6 col-sm-6 mb-3">
            <AvField
              label={`${Translate[lang]?.first_name}*`}
              name="first_name"
              type="text"
              value={formData?.first_name}
              errorMessage="Please enter a valid First Name"
              validate={{
                required: {
                  value: true,
                  errorMessage: Translate[lang].field_required,
                },
              }}
              placeholder={`${Translate[lang]?.first_name}`}
              onChange={(e) => inputHandler(e)}
            />
          </div>
          <div className="col-lg-6 col-sm-6 mb-3">
            <AvField
              label={`${Translate[lang]?.last_name}*`}
              name="last_name"
              type="text"
              value={formData?.last_name}
              errorMessage="Please enter a valid Last Name"
              validate={{
                required: {
                  value: true,
                  errorMessage: Translate[lang].field_required,
                },
              }}
              placeholder={`${Translate[lang]?.last_name}`}
              onChange={(e) => inputHandler(e)}
            />
          </div>
          {!location?.state?.edit && (
            <div className="col-lg-6 col-sm-6 mb-3">
              <AvField
                label={`${Translate[lang]?.email}*`}
                name="email"
                type="email"
                value={formData?.email}
                errorMessage="Please enter a valid Email"
                validate={{
                  required: {
                    value: true,
                    errorMessage: Translate[lang].field_required,
                  },
                }}
                placeholder="example@example.com"
                onChange={(e) => inputHandler(e)}
              />
            </div>
          )}
          {!location?.state?.edit && (
            <div className="col-lg-6 col-sm-6 mb-3">
              <AvField
                label={`${Translate[lang]?.password}*`}
                name="password"
                type={`${showPassword ? "password" : "text"}`}
                value={formData?.password}
                errorMessage="Please enter a valid Password"
                validate={{
                  required: {
                    value: true,
                    errorMessage: Translate[lang].field_required,
                  },
                }}
                onFocus={(e) => setShowPassword(false)}
                onBlur={(e) => setShowPassword(true)}
                placeholder={`${Translate[lang]?.password}`}
                onChange={(e) => inputHandler(e)}
              />
            </div>
          )}
          <div className="col-lg-6 col-sm-6 mb-3">
            <label className="text-label">
              {Translate[lang]?.country_code}*
            </label>
            <Select
              value={formData?.country_code}
              name="country_code"
              placeholder={Translate[lang]?.select}
              options={countriesOptions}
              onChange={(e) => setFormData({ ...formData, country_code: e })}
            />
          </div>
          <div className="col-lg-6 col-sm-6 mb-3">
            <AvField
              label={`${Translate[lang]?.phone}*`}
              name="phone"
              type="number"
              value={formData?.phone}
              errorMessage="Please enter a valid Phone"
              validate={{
                required: {
                  value: true,
                  errorMessage: Translate[lang].field_required,
                },
              }}
              placeholder={`${Translate[lang]?.phone}`}
              onChange={(e) => inputHandler(e)}
            />
          </div>
          {location?.state?.edit && <div className="col-lg-6 col-sm-6 mb-3"> <span
            style={{
              textDecoration: 'underline',
              color: 'var(--blue)',
              fontSize: '16px', cursor: 'pointer'
          
            }}
            onClick={()=> setChangePassword(true)}
          >{Translate[lang].change_password}</span>
          </div>}
          <div className="col-lg-9 col-sm-12 mb-3">
            <Table responsive>
              <thead>
                <tr>
                  <th className="w-50">{Translate[lang]?.rule}</th>
                  <th className="w-25 text-center">
                    {Translate[lang]?.full_permissions}
                  </th>
                  <th className="w-25 text-center">
                    {Translate[lang]?.read_only}
                  </th>
                </tr>
              </thead>
              <tbody>
                {Rules?.map((rul, index) => {
                  return (
                    <tr key={index}>
                      <th style={{ fontWeight: "500" }}>
                        {Translate[lang][rul.value]}
                      </th>
                      <th className="text-center">
                        <input
                          type="radio"
                          style={{
                            width: "20px",
                            height: "20px",
                            accentColor: "var(--primary)",
                          }}
                          name={rul.value}
                          checked={formData?.rules?.includes(rul?.value)}
                          onChange={() =>
                            setFormData({
                              ...formData,
                              rules: [...formData.rules, rul.value],
                            })
                          }
                        />
                      </th>
                      <th className="text-center">
                        <input
                          type="radio"
                          style={{
                            width: "20px",
                            height: "20px",
                            accentColor: "var(--primary)",
                          }}
                          // checked={Object.keys(formData?.admin).length > 0 && !formData.rules?.includes(rul.value)}
                          name={rul.value}
                          onChange={() => {
                            let update = formData?.rules?.filter(
                              (res) => res !== rul.value
                            );
                            setFormData({ ...formData, rules: [...update] });
                          }}
                        />
                      </th>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-4">
          <Button
            variant="secondary"
            type="button"
            onClick={() => navigate("/admins")}
          >
            {Translate[lang]?.cancel}
          </Button>
          <Button variant="primary" type="submit" disabled={loading}>
            {Translate[lang]?.add}
          </Button>
        </div>
      </AvForm> : <AvForm onValidSubmit={handlerChangePassword}>
        <div className="row">
          <div className="col-lg-6 col-sm-6 mb-3">
            <AvField
              label={`${Translate[lang]?.old_password}`}
              name="old_password"
              type="text"
              value={formData?.old_password}
              errorMessage="Please enter a valid First Name"
              validate={{
                required: {
                  value: true,
                  errorMessage: Translate[lang].field_required,
                },
              }}
              placeholder={`${Translate[lang]?.old_password}`}
              onChange={(e) => inputHandler(e)}
            />
          </div>
          <div className="col-lg-6 col-sm-6 mb-3">
            <AvField
              label={`${Translate[lang]?.new_password}`}
              name="new_password"
              type="text"
              value={formData?.new_password}
              errorMessage="Please enter a valid Last Name"
              validate={{
                required: {
                  value: true,
                  errorMessage: Translate[lang].field_required,
                },
              }}
              placeholder={`${Translate[lang]?.new_password}`}
              onChange={(e) => inputHandler(e)}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between mt-4">
          <Button
            variant="secondary"
            type="button"
            onClick={() => navigate("/admins")}
          >
            {Translate[lang]?.cancel}
          </Button>
          <Button variant="primary" type="submit" disabled={loading}>
            {Translate[lang]?.change}
          </Button>
        </div>
      </AvForm>}
    </Card>
  );
};

export default AddAdmin;
