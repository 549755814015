import BaseService from "./BaseService";
import http from './HttpService'

import { API_BASE_URL_ENV } from "../jsx/common/common";

const apiEndpoint = API_BASE_URL_ENV() + "/feedback";

export default class FeedbackService extends BaseService {
    constructor() {
        super(apiEndpoint);
    }

    getList(data) {
        return http.get(`${apiEndpoint}/getAllFeedback?category=${data}`);
    }
}
